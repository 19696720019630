import React from 'react';
import { getIn } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

export function fieldToCheckboxField({
        disabled,
        field,
        form: { isSubmitting, touched, errors},
        ...props
    }) {
    const fieldError = getIn(errors, field.name),
        fieldTouched = getIn(touched, field.name),
        showError = fieldTouched && !fieldError;

    return {
        ...props,
        ...field,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled || isSubmitting,
    };
}

export function CheckboxField(props) {
    const { name, value, onChange, color, helperText, label, ...otherProps} = fieldToCheckboxField(props);

    const checkboxProps = {name, checked: value, onChange, color};

    return <FormControl {...otherProps}>
        <FormControlLabel
            control={
                <Checkbox {...checkboxProps} />
            }
            label={label}
        />
        {otherProps.error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
}

CheckboxField.displayName = 'FormikMuiCheckboxField';