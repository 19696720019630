import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadPanel } from '../../components/load-panel';
import useAxios from '../../hooks/use-axios';
import { useAuthUserContext } from '../../context/auth-user-context';
import ExportButton from './export-button';
import {TrainingSummaryExporter, TrainingTransactionExporter,
    TrainingTransactionSplitExporter} from './export-formats';
import {useDatatableDateRangeTitle} from './datatable';
import Datatable from './datatable';
import {GROUPED_TABLE_COLUMNS, CLASS_NAME, NOT_VISIBLE, REGISTRATION_ID, TOTAL_SEATS,
    TRANSACTION_DATE, GROSS_PRICES, DISCOUNT, NET_TOTAL_SALE, NET_SALE_PRICES, TAX,
    SPLIT_TOTAL} from './sales-columns';
import ExpandableRow from './detail-row';
import DetailsTable from "./details";

const CSV_FILE_NAME = "SalesByClass.csv";

export default function TrainingSalesList() {
    const { t } = useTranslation();
    const { authUser } = useAuthUserContext();
    const [start, end, title, printTitle] = useDatatableDateRangeTitle(t('page.sales.by_training.title'),
        t('page.sales.by_training.full_title'));

    const [ { data, loading, error }] = useAxios({
        url: '/api/sales/groupby/training',
        params: { start: start.toISOString(), end: end.toISOString()}
    });

    const trainigns = data || [];

    const columns = GROUPED_TABLE_COLUMNS(trainigns, CLASS_NAME, authUser);
    const detailsColumns = [
        NOT_VISIBLE('id'),
        NOT_VISIBLE('splits'),
        REGISTRATION_ID(authUser),
        TOTAL_SEATS(),
        TRANSACTION_DATE(),
        GROSS_PRICES(),
        DISCOUNT(),
        NET_SALE_PRICES(),
        TAX(),
        NET_TOTAL_SALE({
            display: authUser.superadmin,
            filter: authUser.superadmin,
            download: authUser.superadmin,
        }),
        SPLIT_TOTAL()
    ];

    const exportButton = new ExportButton(CSV_FILE_NAME, [
        new TrainingSummaryExporter(trainigns, authUser),
        new TrainingTransactionExporter(trainigns, authUser),
        new TrainingTransactionSplitExporter(trainigns, authUser)
    ]);

    return (
        <LoadPanel loading={loading} showContent error={error || undefined}>
            <Datatable
                title={title}
                data={trainigns}
                columns ={columns}
                customToolbar={() => exportButton.render()}
                renderExpandableRow={(rowData) => {
                    const id = rowData[0],
                        training = trainigns.find(t => t.id === id),
                        transactions = training.transactions,
                        detailexExportButton = new ExportButton(CSV_FILE_NAME, [
                            new TrainingTransactionExporter([training], authUser),
                            new TrainingTransactionSplitExporter([training], authUser)
                        ]);
                    return <ExpandableRow key={id} colSpan={rowData.length + 1}>
                        <DetailsTable data={transactions} columns={detailsColumns}
                            title={printTitle} exportButton={detailexExportButton}
                        />
                    </ExpandableRow>
                }}
            />
        </LoadPanel>
    );
}