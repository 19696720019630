import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from '../../../components/text-field';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useAuthUserContext } from '../../../context/auth-user-context';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import L from '../../../../locale';
import LoadingButton from '../../../components/loading-button';

const Schema = Yup.object().shape({
    oldPassword: Yup.string().required(L.t('general.text.validation.required')),
    password: Yup.string()
        .min(6, L.t('general.text.validation.password_must_be_more_or_equals', { count: 6 }))
        .required(L.t('general.text.validation.required')),
    confirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('password'), null], L.t('general.text.validation.password_must_match'))
});

export default function ChangePasswordDialogForm({onClose}) {
    const { t } = useTranslation();
    const history = useHistory();
    const { updatePassword } = useAuthUserContext();
    const [passwordUpdated, setPasswordUpdated] = useState('');
    const onSubmitHandler = ({password, oldPassword}) =>
        updatePassword(oldPassword, password, () => {
            setPasswordUpdated(true);
            return new Promise((res) => setTimeout(res, 4000));
        });
    useEffect(() => {
        if(passwordUpdated) {
            const timeout = setTimeout(() => history.push('/login'), 4000);
            return () => clearTimeout(timeout);
        }
    }, [passwordUpdated, history]);
    const fieldProps = {
        variant: "outlined",
        margin: 'dense',
        fullWidth: true
    };

    return <Formik
        validateOnBlur={false}
        validateOnMount={true}
        initialValues={{password: '', oldPassword: '', confirmPassword: ''}}
        validationSchema={Schema}
        onSubmit={onSubmitHandler}
    >
    {({ submitForm, isSubmitting, isValid }) => (
        <Dialog open maxWidth="xs" fullWidth>
            <DialogTitle>{t('page.profile.change_password')}</DialogTitle>
            <DialogContent dividers>
                {!passwordUpdated
                    ? <Form>
                        <Field component={TextField} autoFocus type="password" {...fieldProps}
                          name="oldPassword"
                          label={t('page.profile.old_password')} />
                        <Field component={TextField} type="password" {...fieldProps}
                          name="password"
                          label={t('page.profile.new_password')}/>
                        <Field component={TextField} type="password"  {...fieldProps}
                          name="confirmPassword"
                          label={t('page.profile.confirm_password')}/>
                    </Form>
                    : <Alert severity="success">{t('page.profile.password_updated_message')}</Alert>
                }
            </DialogContent>
            {!passwordUpdated && <DialogActions>
                <LoadingButton variant="contained" color="primary" disabled={!isValid || isSubmitting}
                  onClick={submitForm} loading={isSubmitting}>
                    {t('general.text.change')}
                </LoadingButton>
                <Button color="primary" onClick={onClose}>
                    {t('general.text.close')}
                </Button>
          </DialogActions>
          }
        </Dialog>
    )}
    </Formik>
}
