import React, {useState, useMemo} from 'react';

import { useSnackbar } from 'notistack';

import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Mail from '@material-ui/icons/Mail';
import { useTranslation } from 'react-i18next';
import TableLocal from '../../../locale/mui-datatables-localization';

import { LoadPanel } from '../../components/load-panel';
import { NewUserFormDialog, EditUserFormDialog } from './user-form-dialog';
import UserRemoveDialog from './user-remove-dialog';
import useAxios from '../../hooks/use-axios';

import { useAuthUserContext } from '../../context/auth-user-context';


const useStyles = makeStyles((theme) => ({
    title: {
        verticalAlign: 'middle'
    },
    notVerifiedIcon: {
        marginLeft: "0.5rem"
    }
}));

const muiTableTheme = createMuiTheme({
    overrides: {
        MUIDataTableHeadCell: {
            sortLabelRoot: {
                height: 'auto'
            }
        },
        MuiTableCell: {
            alignRight: {
                '& > span': {
                    justifyContent: 'flex-end'
                }
            }
        },
        MUIDataTableToolbar: {
            root: {
                paddingLeft: 0
            }
        }
    }
})

const Datatable = ({options={}, ...props}) => {
    const newOptions = {
        ...options,
        textLabels: {
            ...TableLocal,
            ...((options && options.textLabels) || {})
        }
    };

    return <MuiThemeProvider theme={muiTableTheme}>
            <MUIDataTable {...props} options={newOptions}/>
        </MuiThemeProvider>
}

const AddUserButton = props =>
    <IconButton {...props}>
        <AddCircleIcon color="primary" fontSize="large" />
    </IconButton>

const EditUserButton = props =>
    <IconButton size="small" {...props}>
        <EditIcon fontSize="small"/>
    </IconButton>

const DeleteUserButton = props =>
    <IconButton size="small" {...props}>
        <DeleteIcon fontSize="small" />
    </IconButton>

const EmailNotVerifiedIcon = props => {
    const { t } = useTranslation();

    return <Tooltip title={t('general.text.email_not_verified')}>
        <Badge badgeContent="!" color="error" {...props} >
            <Mail />
        </Badge>
    </Tooltip>
}

const UserStatusSelect = ({value, onChange}) => {
    const {t} = useTranslation();
    return <FormControl variant="outlined" margin="dense" style={{marginLeft: '15px'}}>
        <InputLabel id="user-state-select-label">{t('page.users.status')}</InputLabel>
        <Select labelId="user-state-select-label" value={value} onChange={onChange}
            label={t('page.users.status')}>
            <MenuItem value="all">{t('page.users.status.all')}</MenuItem>
            <MenuItem value={true}>{t('page.users.status.active')}</MenuItem>
            <MenuItem value={false}>{t('page.users.status.deleted')}</MenuItem>
        </Select>
    </FormControl>
}

const prepareUserDataForForm = ({ fullName, email }) => {
    return {
        fullName,
        email
    }
}

const prepareUserDataForRequest = (userData) => {
    return {
        ...userData,
        password: userData.password || undefined,
        oldPassword: userData.oldPassword || undefined
    }
}

export default function UsersList() {
    const { authUser } = useAuthUserContext();
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [active, setActive] = useState(true);
    const [showAddUserDialog, setAddUserDialogVisibility] = useState(false);
    const [editableUser, selectUserForEdit] = useState(null);
    const [removableUser, selectUserForRemove] = useState(null);

    const [{data, loading, error }, fetchUsers] = useAxios('/api/users');

    const [, createUserRequest] = useAxios({
        url: '/api/users',
        method: 'POST'
    }, { manual: true });

    const [, updateUserRequest] = useAxios({
        method: 'PUT'
    }, { manual: true });

    const [, removeUserRequest] = useAxios({
        method: 'DELETE'
    }, { manual: true });

    const users = useMemo(() => {
        if(!data) {
            return [];
        }
        if(!(typeof active === "boolean")) {
            return data;
        }
        return data.filter(u => u.active === active);
    }, [data, active]);


    const createUser = (userData) => {
        return createUserRequest({
            data: prepareUserDataForRequest(userData)
        }).then(() => {
            fetchUsers();
            setAddUserDialogVisibility(false);
            enqueueSnackbar(`Saved!`, {
                variant: 'success',
            });
        });
    }

    const updateUser = (userData) => {
        return updateUserRequest({
            data: prepareUserDataForRequest(userData),
            url: `/api/users/${editableUser.id}`
        }).then(() => {
            fetchUsers();
            selectUserForEdit(null);
            enqueueSnackbar(`Saved!`, {
                variant: 'success',
            });
        });
    }

    const onClickEditBtnHandler = (userId) => {
        const user = (users || []).find(user => user.id === userId)

        user && selectUserForEdit(user);
    }

    const onClickRemoveBtnHandler = (userId) => {
        const user = (users || []).find(user => user.id === userId)

        user && selectUserForRemove(user);
    }

    const handleActiveChange = (e) => setActive(e.target.value);

    const onRemoveUserHandler = () => {
        return removeUserRequest({
            url: `/api/users/${removableUser.id}`
        }).then(() => {
            fetchUsers();
            enqueueSnackbar(`Removed!`, {
                variant: 'success',
            });
            selectUserForRemove(null);
        });
    }

    return (
        <>
            <LoadPanel loading={loading} showContent error={error || undefined}>
                <Datatable
                    title={[
                        <AddUserButton key="0" onClick={() => setAddUserDialogVisibility(true)}/>,
                        <Typography key="1" variant="h6" component="span" className={classes.title}>{
                            t('general.text.users')
                        }</Typography>,
                        <UserStatusSelect key="2" value={active} onChange={handleActiveChange}/>
                        ]}
                    data={users}
                    columns={[{
                        name: 'emailVerified',
                        options: {
                            display: 'excluded'
                        }
                    }, {
                        name: 'active',
                        options: {
                            display: 'excluded'
                        }
                    }, {
                        name: 'fullName',
                        label: t('page.users.user_name'),
                        options: {
                            customBodyRender: (fullName, meta) => <>
                                {fullName}
                                {meta.rowData[0] === false &&
                                    <EmailNotVerifiedIcon className={classes.notVerifiedIcon}/>
                                }
                            </>
                        }
                    }, {
                        name: 'email',
                        label: t('page.users.email_address')
                    // INFO: Сan be used later
                    // }, {
                    //     name: 'phone',
                    //     label: t('page.users.phone_number')
                    }, {
                        name: 'id',
                        label: t('page.users.actions'),
                        options: {
                            filter: false,
                            download: false,
                            setCellProps: () => ({ align: 'right' }),
                            setCellHeaderProps: () => ({align: 'right'}),
                            customBodyRender: (userId, meta) => {
                                const isActiveUser = meta.rowData[1] === true;
                                const isAuthUser = userId === authUser?.id;

                                return isActiveUser
                                            ? [
                                                <EditUserButton key="0"
                                                    onClick={() => onClickEditBtnHandler(userId)}/>,
                                                <DeleteUserButton key="1" disabled={isAuthUser}
                                                    onClick={() => onClickRemoveBtnHandler(userId)}/>
                                            ]
                                            : null;
                            }
                        }
                    }]}
                    options={{
                        filterType: 'textField',
                        selectableRows: 'none',
                        viewColumns: false,
                        filter: false,
                        responsive: 'standard',
                    }}
                />
            </LoadPanel>
            {showAddUserDialog &&
                <NewUserFormDialog
                    onClose={() => setAddUserDialogVisibility(false)}
                    onSave={createUser} />
            }
            {editableUser &&
                <EditUserFormDialog
                    userId={editableUser.id}
                    formData={prepareUserDataForForm(editableUser)}
                    onClose={() => selectUserForEdit(null)}
                    onSave={updateUser} />
            }
            {removableUser &&
                <UserRemoveDialog
                    user={removableUser}
                    onClose={() => selectUserForRemove(null)}
                    onRemove={onRemoveUserHandler}/>
            }
        </>
    );
}