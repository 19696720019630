import React from 'react';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

export default function LoadingButton({loading, ...buttonProps}) {
    const classes = useStyles();
    const ButtonWithProps = React.createElement(Button, buttonProps);
    return <span className={classes.button}>
        {ButtonWithProps}
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </span>
}