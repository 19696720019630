import currency from 'currency.js';

export const executeAsPromise = (resultOfFunc) => {
    if (resultOfFunc instanceof Promise) {
    return resultOfFunc;
    }

    return Promise.resolve(resultOfFunc);
}

export const round2 = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

export const formatDiscountValue = (discount) => {
    if(discount == null) {
        return '';
    }
    return discount.type === "AMOUNT"
        ? currency(discount.value).format()
        : discount.type === "PERCENT"
            ? `${discount.value}%`
            : discount.value;
};

export const formatDiscount = (discount) => {
    if(discount == null) {
        return null;
    }
    return `${discount.name} (${formatDiscountValue(discount)})`;
}