import React from 'react';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Badge from '@material-ui/core/Badge';
import GroupIcon from '@material-ui/icons/Group';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ClassIcon from '@material-ui/icons/Class';

const EditGroupButton = props =>
  <IconButton aria-label="settings" {...props}>
    <EditIcon />
  </IconButton>

const DeleteGroupButton = props =>
  <IconButton aria-label="settings" {...props}>
    <DeleteIcon />
  </IconButton>

const GroupTitle = ({group}) =>
  <Typography variant="h5">{group.name}
    &nbsp;<Badge badgeContent={(group.toolboxes || []).length} color="secondary">
      <LibraryBooksIcon/>
    </Badge>
    &nbsp;&nbsp;<Badge badgeContent={(group.trainings || []).length} color="secondary">
      <ClassIcon/>
    </Badge>
    &nbsp;&nbsp;<Badge badgeContent={(group.nestedGroups || []).length} color="secondary">
      <GroupIcon/>
    </Badge>
  </Typography>

const GroupSubheader = ({group}) =>
  <Typography variant="body1" color="textSecondary">
    {
      moment(group.auditInfo.createdAt).format('MMMM Do YYYY, h:mm:ss a')
    } ({
      moment(group.auditInfo.createdAt).fromNow()
    })
  </Typography>

export default function Group(props) {
  const group = props.data;
  const isImmutable = props.isImmutable;

  const onEditBtnClick = () => props.onEditBtnClick && props.onEditBtnClick(group);
  const onDeleteBtnClick = () => props.onDeleteBtnClick && props.onDeleteBtnClick(group);

  return  <Card>
            <CardHeader
              disableTypography
              action={[
                <EditGroupButton key="0" onClick={onEditBtnClick}/>,
                <DeleteGroupButton disabled={isImmutable} key="1" onClick={onDeleteBtnClick}/>
              ]}
              title={
                <GroupTitle group={group}/>
              }
              subheader={
                <GroupSubheader group={group}/>
              }
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {group.description}
              </Typography>
            </CardContent>
          </Card>
}
