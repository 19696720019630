import L from '/';

export default {
    body: {
        noMatch: L.t('table.body.noMatch'),
        toolTip: L.t('table.body.toolTip'),
        columnHeaderTooltip: column => L.t('table.body.columnHeaderTooltip', {
            columnLabel: column.label
        }),
    },
    pagination: {
        next: L.t('table.pagination.next'),
        previous: L.t('table.pagination.previous'),
        rowsPerPage: L.t('table.pagination.rowsPerPage'),
        displayRows: L.t('table.pagination.displayRows'),
    },
    toolbar: {
        search: L.t('table.toolbar.search'),
        downloadCsv: L.t('table.toolbar.downloadCsv'),
        print: L.t('table.toolbar.print'),
        viewColumns: L.t('table.toolbar.viewColumns'),
        filterTable: L.t('table.toolbar.filterTable'),
    },
    filter: {
        all: L.t('table.filter.all'),
        title: L.t('table.filter.title'),
        reset: L.t('table.filter.reset'),
    },
    viewColumns: {
        title: L.t('table.viewColumns.title'),
        titleAria: L.t('table.viewColumns.titleAria'),
    },
    selectedRows: {
        text: L.t('table.selectedRows.text'),
        delete: L.t('table.selectedRows.delete'),
        deleteAria: L.t('table.selectedRows.deleteAria'),
    },
};