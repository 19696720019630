import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EntryViewWrapper from '../login/entry-view-wrapper';

import { LoadPanel } from '../../components/load-panel';
import useAxios from '../../hooks/use-axios';


const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%' // Fix IE 11 issue
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    loading: {
        verticalAlign: 'middle'
    }
}));

export default function SetPassword() {
    const classes = useStyles();
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [ { loading }, setNewPasswordRequest ] = useAxios('/api/auth/passwordrecovery', { manual: true });

    const [password, setPassword] = useState('');

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const handleSubmit = (e) => {
        e.preventDefault();

        setNewPasswordRequest({
            data: { token, password },
            method: 'POST'
        })
            .then(() => {
                enqueueSnackbar(t('page.set_password.notification.success_set_new_password'), {
                    variant: 'success',
                });
            });
    }

    return (
        <EntryViewWrapper>
           <LoadPanel wrapperComponent={Box} textAlign="center" showContent loading={loading}>
                <Typography component="h1" variant="h5">
                    {t('page.set_password.set_password')}
                </Typography>
                <br />
                <Typography component="h2" variant="body2">
                    {t('page.set_password.pass_therms')}
                </Typography>
                <br />
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('general.text.password')}
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={loading}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        {t('general.text.save')}
                    </Button>
                    <Link href="/login" variant="body2">
                        {t('page.login.signin')}
                    </Link>
                </form>
            </LoadPanel>
        </EntryViewWrapper>
    );
}