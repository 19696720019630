import {useCallback, useEffect} from 'react';
import {makeUseAxios} from 'axios-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAuthUserContext } from '../context/auth-user-context';

const useAxiosHook = makeUseAxios({cache: false});

export default function useAxios(/*axios-hooks: url|config, axios-hooks: options & {showNotification: boolean}*/) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {clearUser} = useAuthUserContext();

    const showErrorNotification = useCallback((transKey, message, options) =>
        enqueueSnackbar(`${t(transKey)} ${message || ""}`, {variant: 'error', ...options}),
        [enqueueSnackbar, t]
    );

    const result = useAxiosHook.apply(null, arguments);
    const [{error}] = result;

    const showNotification = !arguments[1] || arguments[1].showNotification !== false;

    useEffect(() => {
        if (error) {
            if(error.response && error.response.status === 401) {
                showErrorNotification('page.notification.not_authenticated', '', {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                    autoHideDuration: 2500
                });
                setTimeout(clearUser, 3000);
            } else if(showNotification) {
                let message = (error.response && error.response.data && error.response.data.message)
                    || error.message;
                showErrorNotification('page.notification.error', message);
            }
        }
    }, [error, showErrorNotification, clearUser, showNotification]);

    return result;
}