import React from 'react';

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';

const useShowWithContentStyles = makeStyles({
  contentContainer: {
    opacity: props => props.contentOpacity
  },
  wrapper: {
    position: "relative"
  },
  subWrapper: {
    height:"100%",
    width:"100%"
  },
  loaderContainer: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  }
});

const useLoaderContainerStyles = makeStyles({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
});

const LoaderContainer = ({
  wrapperComponent: Wrapper = 'div',
  className,
  ...props
}) => {
  const classes = useLoaderContainerStyles();

  return (
    <Wrapper {...props} className={`${classes.loaderContainer} ${className}`}>
      <CircularProgress />
    </Wrapper>
  )
}

export function LoadPanel({
  showContent = false,
  loading, children, contentOpacity=0.5,
  wrapperComponent: Wrapper = 'div',
  className: loadPanelClassName='',
  ...props
}) {
  const classes = useShowWithContentStyles({ contentOpacity });

  if (showContent) {
    const className = loading ? classes.contentContainer : '';
    return (
      <Wrapper className={`${classes.wrapper} ${loadPanelClassName}`} {...props}>
        <Wrapper className={`${classes.subWrapper} ${className}`}>
          {children}
        </Wrapper>
        {loading && <LoaderContainer wrapperComponent={Wrapper} className={classes.loaderContainer}/>}
      </Wrapper>
    )
  } else {
    if (loading) {
      return <LoaderContainer {...props} wrapperComponent={Wrapper} className={loadPanelClassName}/>
    }

    return children;
  }
}

LoadPanel.displayName = 'MaterialUILoadPanel';