import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import backgroundImage from '../../../img/background.png';
import poweredbyLogo from '../../../img/poweredby_logo.png';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        paddingTop: theme.spacing(10)
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: theme.spacing(3, 4, 2, 4),
        borderRadius: 5
    },
}));

export default function EntryViewWrapper(props) {
    const classes = useStyles();
    return (
        <Container maxWidth={false} className={classes.container}>
            <Container component="main" maxWidth="xs" disableGutters>
                <Box className={classes.paper}>{props.children}</Box>
                <Box textAlign="center" paddingTop={2}>
                    <img src={poweredbyLogo} alt="powered by logo"/>
                </Box>
            </Container>
        </Container>
    );
}

EntryViewWrapper.propTypes = {
    children: PropTypes.element
};