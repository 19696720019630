import React, {useState} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    loading: {
        marginRight: '0.6rem'
    }
});

export default function UserRemoveDialog({onRemove, onClose, user}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isSubmitting, setSubmittingState] = useState(false);

    const onRemoveBtnClickHandler = () => {
        setSubmittingState(true);

        const removeRes = onRemove(user)

        if (removeRes && removeRes.finally) {
            removeRes.finally(() => setSubmittingState(false));
        } else {
            setSubmittingState(false);
        }
    };

    return <Dialog open fullWidth>
                <DialogTitle>{t('general.text.remove')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('page.users.remove_user_question', {
                            name: (user && user.fullName) || t('general.text.no_name')
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isSubmitting} variant="contained" color="secondary" onClick={onRemoveBtnClickHandler}>
                        {isSubmitting && <CircularProgress className={classes.loading} color={'inherit'} size={20} />}
                        {t('general.text.remove')}
                    </Button>
                    <Button onClick={onClose}>{t('general.text.cancel')}</Button>
                </DialogActions>
            </Dialog>
}