import React, {useState} from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Badge from '@material-ui/core/Badge';
import GroupIcon from '@material-ui/icons/Group';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { LoadPanel } from '../../components/load-panel';
import { executeAsPromise } from '../../utils';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  disabledCard: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)'
  },
  activityStatusComponent: {
    borderRightColor: theme.palette.grey[400]
  }
}));

const EditGroupButton = props =>
  <IconButton aria-label="settings" {...props}>
    <EditIcon />
  </IconButton>

const DeleteGroupButton = props =>
  <IconButton aria-label="settings" {...props}>
    <DeleteIcon />
  </IconButton>

const SplitTitle = ({ split }) =>
  <Typography variant="h5">{split.name} - {split.percent}%
    &nbsp;<Chip icon={<GroupIcon />} label={split.group.name} />
    &nbsp;<Badge badgeContent={split.users.length} color="secondary">
      <VisibilityIcon />
    </Badge>
  </Typography>

const SplitSubheader = ({ split }) =>
  <Typography variant="body1" color="textSecondary">
    {
      moment(split.auditInfo.createdAt).format('MMMM Do YYYY, h:mm:ss a')
    } ({
      moment(split.auditInfo.createdAt).fromNow()
    })
  </Typography>

const ActivityStatus = ({ split, onChange, loading, disabled, ...props }) => {
  const { t } = useTranslation();
  const [activeChangedAt, setActiveChangedAt] = useState(moment(split.activeChangedAt));
  const [splitActivity, setSplitActivity] = useState(split.active);

  const changeActivityHandler = (e) => {
    if (!onChange) return;

    setSplitActivity(e.target.checked);

    const saveRes = onChange(e.target.checked);

    if (saveRes && saveRes.catch) {
      saveRes
        .then(() => setActiveChangedAt(moment()))
        .catch(() => setSplitActivity(sa => !sa));
    }
  }

  return <LoadPanel loading={loading} contentOpacity={0.2} showContent wrapperComponent={Box} height="100%">
    <Box height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column" {...props}>
      <FormControlLabel
        value="bottom"
        control={
          <Switch checked={splitActivity} color="primary" disabled={disabled} onChange={changeActivityHandler}/>
        }
        label={splitActivity
          ? t('page.splits.active')
          : t('page.splits.inactive')}
        labelPlacement="bottom"
      />
      <Typography variant="caption" color="textSecondary" component="p">
        ({activeChangedAt.isValid() ? activeChangedAt.fromNow() : moment(split.auditInfo.createdAt).fromNow()})
      </Typography>
    </Box>
  </LoadPanel>
}


export default function Split(props) {
  const classes = useStyles();
  const [activityChanging, setActivityChanging] = useState(false);

  const split = props.data;
  const isImmutable = props.isImmutable;

  const onEditBtnClick = () => props.onEditBtnClick && props.onEditBtnClick(split);
  const onDeleteBtnClick = () => props.onDeleteBtnClick && props.onDeleteBtnClick(split);
  const onChangeActivityHandler = (active) => props.onChangeActivity && props.onChangeActivity(active, split);

  const changeSplitActivityHandler = (active) => {
    setActivityChanging(true);

    return executeAsPromise(onChangeActivityHandler(active))
      .finally(() => {
        setActivityChanging(false);
      })
  }

  return <Card className={!split.active ? classes.disabledCard : ''}>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={3} md={2} xl={1} display="flex">
        <ActivityStatus split={split} loading={activityChanging} onChange={changeSplitActivityHandler}
          className={classes.activityStatusComponent}
          borderRight={1}
          disabled={isImmutable}
        />
      </Grid>
      <Grid item xs={12} sm={7} md={10} xl={11}>
        <CardHeader
          disableTypography
          action={!isImmutable && [
            <EditGroupButton key="0" disabled={activityChanging} onClick={onEditBtnClick} />,
            <DeleteGroupButton key="1" disabled={activityChanging} onClick={onDeleteBtnClick} />
          ]}
          title={<SplitTitle split={split}/>}
          subheader={<SplitSubheader split={split}/>}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {split.description}
          </Typography>
        </CardContent>
      </Grid>
    </Grid>
  </Card>
};