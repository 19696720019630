import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EntryViewWrapper from '../login/entry-view-wrapper';

export default function ForgotPassword() {
    const { t } = useTranslation();

    return (
        <EntryViewWrapper>
            <>
                <Typography component="h1" variant="h4">
                    {t('page.email_verified.header')}
                </Typography>
                <br />
                <Box textAlign="center">
                    <Typography component="h2" variant="subtitle1">
                        {t('page.email_verified.information_text')}
                    </Typography>
                </Box>
                <br />
                <Box textAlign="center">
                    <Button
                        href="/login"
                        variant="contained"
                        color="primary"
                    >
                        {t('page.login.signin')}
                    </Button>
                </Box>
            </>
        </EntryViewWrapper>
    );
}