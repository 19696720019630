import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerPaper: {
        backgroundColor: '#343a40',
        width: drawerWidth
    }
}));

export default function NavBar(props) {
    const classes = useStyles();
    const container = window !== undefined ? () => window.document.body : undefined;

    return  <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
            <Drawer container={container} variant="temporary" open={props.open}
                onClose={props.onClose} classes={{paper: classes.drawerPaper}}
                ModalProps={{keepMounted: true}}>
                {props.children}
            </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
            <Drawer variant="permanent" open classes={{paper: classes.drawerPaper}}>
                {props.children}
            </Drawer>
        </Hidden>
    </nav>
}