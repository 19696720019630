import React from 'react';
import Box from '@material-ui/core/Box';
import currency from 'currency.js';
import i18next from 'i18next';
import moment from 'moment';
import {formatDiscount} from '../../utils';
import {round2} from '../../utils';

const t = i18next.t.bind(i18next);

export const currencyBodyRender = (value) => value ? currency(value).toString() : value;

export const numberAlignOptions = {
    setCellHeaderProps: () => ({align: 'right'}),
    setCellProps: () => ({align: 'right'})
};

export const columnCurrencyOptions = {
    ...numberAlignOptions,
    customBodyRender: currencyBodyRender
};

export const headLabelRender = (column, value) => {
    return <div>
        {column.label}
        <div>{column.customBodyRender ? column.customBodyRender(value) : value}</div>
    </div>;
}

export const grandTotalHeadLabelRender = (data) => ({index, colPos, ...column}) => {
    return headLabelRender(column, data[column.name]);
}

export const grandTotalHeadLabelOptions = data =>
    ({customHeadLabelRender: grandTotalHeadLabelRender(data)});

export const TOOLBOX_NAME = (options) => ({
    name: 'name',
    label: t('page.sales.tml_module_name'),
    options: options
});

export const CLASS_NAME = (options) => ({
    name: 'name',
    label: t('page.sales.class_name'),
    options: options
});

export const TRANSACTION_COUNT = (options) => ({
    name: 'transactionCount',
    label: t('page.sales.transaction_count'),
    options: {
        ...numberAlignOptions,
        ...options
    }
});

export const TOTAL_SEATS = (options) => ({
    name: 'seats',
    label: t('page.sales.total_seats'),
    options: {
        ...numberAlignOptions,
        ...options
    }
});

export const GROSS_PRICES = (options) => ({
    name: 'gross',
    label: t('page.sales.gross_prices', {currency: '$'}),
    options: {
        ...columnCurrencyOptions,
        ...options
    }
});

export const NET_SALE_PRICES = (options) => ({
    name: 'net',
    label: t('page.sales.net_sale_prices', {currency: '$'}),
    options: {
        ...columnCurrencyOptions,
        ...options
    }
});

export const TAX = (options) => ({
    name: 'tax',
    label: t('page.sales.tax', {currency: '$'}),
    options: {
        ...columnCurrencyOptions,
        ...options
    }
});

export const NET_TOTAL_SALE = (options) => ({
    name: 'netTotalSale',
    label: t('page.sales.net_total_sale', { currency: '$' }),
    options: {
        ...columnCurrencyOptions,
        ...options
    }
});

export const SPLIT_TOTAL = (options) => ({
    name: 'splitTotal',
    label: t('page.sales.viewers_split_total', { currency: '$' }),
    options: {
        ...columnCurrencyOptions,
        ...options
    }
});

export const TRANSACTION_DATE = (options) => ({
    name: 'createdAt',
    label: t('page.sales.sell_date'),
    options: {
        customBodyRender: (v) => moment(v).format('MMMM Do YYYY, h:mm:ss a'),
        ...options
    }
});

export const LICENSE_ID = (authUser) => ({
    name: authUser && authUser.superadmin ? 'customerName' : 'licenseId',
    label: t('page.sales.license_id')
});

export const REGISTRATION_ID = (authUser) => ({
    name: authUser && authUser.superadmin ? 'customerName' : 'registrationId',
    label: t('page.sales.registration_id')
});

export const ACTIVE_SEATS = (options) => ({
    name: 'seats',
    label: t('page.sales.seats_active'),
    options: {
        ...numberAlignOptions,
        ...options
    }
});

export const DISCOUNT = (options) => ({
    name: 'discount',
    label: t('page.sales.discount'),
    options: {
        customBodyRender: (v) => formatDiscount(v),
        ...options
    }
});

export const NOT_VISIBLE = (name) => ({
    name,
    options: {
        display: false,
        filter: false,
        download: false
    }
});

export const SPLIT_NAME = (options) => ({
    name: 'name',
    label: t('page.sales.split_name'),
    options
});

export const SPLIT_ITEM_NAME = (options) => ({
    name: 'name',
    label: ' ',
    options
});

export const calculateTransactionsGrandTotal = (transactionItems, authUser) => {
    const visitedTransactions = {};
    const visitedItemTransactions = {};
    let transactionCount = 0, gross = 0, net = 0, seats = 0,
        tax = 0, splitTotal = 0, netTotalSale;
    transactionItems.forEach(item => {
        splitTotal = round2(splitTotal +  item.splitTotal);
        const itemTransactions = (visitedItemTransactions[item.id] = visitedItemTransactions[item.id] || {});
        item.transactions.forEach(tr => {
            const isTransactionVisited = !!visitedTransactions[tr.id];
            if(!isTransactionVisited) {
                visitedTransactions[tr.id] = true;
                tax = round2(tax + tr.tax);
                transactionCount += 1;
                if(tr.licenseId != null) {
                    seats += tr.seats;
                }
            }
            // if transactionItems are items from split groups,
            // i.e. we can have mutliple same items with same transaction
            if(!itemTransactions[tr.id]) {
                itemTransactions[tr.id] = true;
                gross = round2(gross + tr.gross);
                net = round2(net + tr.net);
                if(tr.registrationId != null) {
                    seats += tr.seats;
                }
            }
        });
    });
    if(authUser.superadmin) {
        netTotalSale = round2(net - splitTotal);
    }
    return {transactionCount, gross, net, seats, tax, splitTotal, netTotalSale};
}

export const GROUPED_TABLE_COLUMNS = (data, NAME_COLUMN, authUser) => {
    const totals = calculateTransactionsGrandTotal(data, authUser);
    const showNetTotalSale = authUser.superadmin;
    return [
        NOT_VISIBLE('id'),
        NAME_COLUMN
            ? NAME_COLUMN({
                customHeadLabelRender: ({label}) => <Box>
                        {label}
                    <Box>&nbsp;</Box>
                </Box>
            })
            : {},
        TRANSACTION_COUNT(grandTotalHeadLabelOptions(totals)),
        TOTAL_SEATS(grandTotalHeadLabelOptions(totals)),
        GROSS_PRICES(grandTotalHeadLabelOptions(totals)),
        NET_SALE_PRICES(grandTotalHeadLabelOptions(totals)),
        TAX(grandTotalHeadLabelOptions(totals)),
        NET_TOTAL_SALE({
            display: showNetTotalSale,
            filter: showNetTotalSale,
            download: showNetTotalSale,
            ...grandTotalHeadLabelOptions(totals)
        }),
        SPLIT_TOTAL(grandTotalHeadLabelOptions(totals))
    ];
}



