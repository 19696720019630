import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthUserContext } from '../../context/auth-user-context';
import EntryViewWrapper from './entry-view-wrapper';

import { LoadPanel } from '../../components/load-panel';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%' // Fix IE 11 issue
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function SignIn() {
    const classes = useStyles();
    const {t} = useTranslation();

    const { isSigningIn, signIn } = useAuthUserContext();

    const [email, setEmail] = useState(''),
        [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        signIn(email, password);
    }

    return (
        <EntryViewWrapper>
            <LoadPanel wrapperComponent={Box} textAlign="center" showContent loading={isSigningIn}>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('general.text.email_address')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={isSigningIn}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('general.text.password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={isSigningIn}
                    />
                    {/* INFO: No API support at the moment! */}
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label={t('page.login.remember_me')}
                        disabled={isSigningIn}
                    /> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSigningIn}
                    >
                        {t('page.login.signin')}
                    </Button>
                    <Link href="/forgot-password" variant="body2">
                        {t('page.login.forgot_password')}
                    </Link>
                </form>
            </LoadPanel>
        </EntryViewWrapper>
    );
}