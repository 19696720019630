import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
import {currencyBodyRender} from './sales-columns';

const useStyles = makeStyles((theme) => ({
    splitTable: {
        "& tr:last-child > td": {
            borderBottom: 'none'
        }
    }
}));

export default function SplitTable({data: splits=[]}) {
    const classes = useStyles();

    return <Table className={classes.splitTable} size={"small"}>
        <TableBody>
            {splits &&
                splits.map((split) => <TableRow key={split.name}>
                    <TableCell >{split.name}</TableCell>
                    <TableCell align="center">{split.percent + "%"}</TableCell>
                    <TableCell align="right">{currencyBodyRender(split.amount)}</TableCell>
                </TableRow>)
            }
        </TableBody>
    </Table>
}