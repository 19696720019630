import React, {useMemo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        color: 'white'
    },
    listItem: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.14)'
        }
    },
    nestedListItem: {
        paddingLeft: theme.spacing(4)
    },
    nestedListItemText: {
        fontSize: '0.9rem'
    }
}));

export default function ListItemLink(props) {
    const { icon, primary, to, children, className, primaryTypographyProps } = props;
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if(children) {
            let isOpen = false;
            React.Children.forEach(children, (child) => {
                    isOpen = isOpen || child.props.to === location.pathname;
            });
            setOpen(isOpen);
        }
    }, [children, location.pathname])

    const renderLink = useMemo(
        () => to ? React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />)
            : null,
        [to],
    );

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <ListItem button component={renderLink} className={classes.listItem + ` ${className}`}
                onClick={handleClick} selected={location.pathname === to}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} primaryTypographyProps={primaryTypographyProps}/>
                {children && <>
                        {open ? <ExpandLess /> : <ExpandMore /> }
                    </>
                }
            </ListItem>
            {children && <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" className={classes.list} disablePadding>
                    {React.Children.map(children, (child) => {
                        return React.cloneElement(child, {
                            className: classes.nestedListItem,
                            primaryTypographyProps: {
                                className: classes.nestedListItemText
                            }
                        });
                    })}
                </List>
            </Collapse>}
        </div>
    );
}

ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string,
};