import moment from 'moment';
import i18next from 'i18next';
import {formatDiscountValue} from '../../utils';

const t = i18next.t.bind(i18next);

const SPLIT_GROUP = {
    name: 'splitGroup',
    label: t('page.sales.split_group')
};

const TOOLBOX_NAME = {
    name: 'name',
    label: t('page.sales.tml_module_name'),
};

const CLASS_NAME = {
    name: 'name',
    label: t('page.sales.class_name')
};

const ITEM_NAME = {
    name: 'name',
    label: t('page.sales.item_name')
};

const TRANSACTION_COUNT = {
    name: 'transactionCount',
    label: t('page.sales.transaction_count')
};

const TOTAL_SEATS = {
    name: 'seats',
    label: t('page.sales.total_seats')
};

const GROSS = {
    name: 'gross',
    label: t('page.sales.gross_prices', {currency: '$'})
};

const NET = {
    name: 'net',
    label: t('page.sales.net_sale_prices', {currency: '$'})
};

const TAX = {
    name: 'tax',
    label: t('page.sales.tax', {currency: '$'})
};

const NET_TOTAL_SALE = (download) => ({
    name: 'netTotalSale',
    label: t('page.sales.net_total_sale', { currency: '$' }),
    options: {download}
});

const SPLIT_TOTAL = {
    name: 'splitTotal',
    label: t('page.sales.viewers_split_total', { currency: '$' })
};

const CUSTOMER_NAME = (download) => ({
    label: t('page.sales.customer_name'),
    name: 'customerName',
    options: {download}
});

const LICENSE_ID = {
    label: t('page.sales.license_id'),
    name: 'licenseId',
};

const REGISTRATION_ID = {
    label: t('page.sales.registration_id'),
    name: 'registrationId',
}

const TRANSACTION_ID = {
    label: t('page.sales.transaction_id'),
    name: 'transactionId'
}

const TRANSACTION_DATE = {
    label: t('page.sales.transaction_date'),
    name: 'createdAt'
}

const SEATS_ACTIVE = {
    label: t('page.sales.seats_active'),
    name: 'activeSeats'
}

const DISCOUNT_NAME = {
    label: t('page.sales.discount_name'),
    name: 'discountName',
}

const DISCOUNT_VALUE = {
    label: t('page.sales.discount_value'),
    name: 'discountValue',
}

const SPLIT_NAME = {
    name: 'splitName',
    label: t('page.sales.split_name')
}

const SPLIT_AMOUNT = {
    name: 'splitAmount',
    label: t('page.sales.split_amount')
};

const LICENSE_OR_REGISTRATION_ID = {
    name: 'licOrRegId',
    label: t('page.sales.license_or_registration')
};

const transactionExportObject = (tr) => ({
    ...tr,
    transactionId: tr.id,
    discountName: tr.discount ? tr.discount.name : '',
    discountValue: formatDiscountValue(tr.discount),
    createdAt: moment(tr.createdAt).format('l')
});

class Exporter {
    constructor(name, data) {
        this.name = name;
        this.data = data;
    }

    getColumns() {throw new Error('not implemented')}

    getData() {
        return this.data;
    }
}

class BaseSummaryExporter extends Exporter {

    constructor(data, includeNetTotalSale) {
        super(t('page.sales.export_summary'), data);
        this.includeNetTotalSale = includeNetTotalSale;
    }

    getColumns() {
        return [
            TRANSACTION_COUNT,
            TOTAL_SEATS,
            GROSS,
            NET,
            TAX,
            NET_TOTAL_SALE(this.includeNetTotalSale),
            SPLIT_TOTAL
        ]
    }
}

class BaseDetailExporter extends Exporter {

    constructor(name, data, includeNetTotalSale, includeCustomerName) {
        super(name, data);
        this.includeNetTotalSale = includeNetTotalSale;
        this.includeCustomerName = includeCustomerName;
    }

    getColumns() {
        return [
            CUSTOMER_NAME(this.includeCustomerName),
            TRANSACTION_ID,
            TRANSACTION_DATE,
            TRANSACTION_COUNT,
            TOTAL_SEATS,
            SEATS_ACTIVE,
            GROSS,
            DISCOUNT_NAME,
            DISCOUNT_VALUE,
            NET,
            TAX,
            NET_TOTAL_SALE(this.includeNetTotalSale),
            SPLIT_TOTAL
        ];
    }
}

class BaseTransactionDetailExporter extends BaseDetailExporter {
    constructor(data, includeNetTotalSale, includeCustomerName) {
        super(t('page.sales.export_summary_with_transactions'), data, includeNetTotalSale,
            includeCustomerName);
    }

    getData() {
        return this.data.reduce((result, item) => {
            result.push(item);
            item.transactions.forEach(tr => {
                result.push({
                    name: item.name,
                    ...tr,
                    transactionId: tr.id,
                    discountName: tr.discount ? tr.discount.name : '',
                    discountValue: formatDiscountValue(tr.discount),
                    createdAt: moment(tr.createdAt).format('l')
                });
            });
            return result;
        }, []);
    }
}

class BaseTransactionWithSplitDetailExporter extends BaseDetailExporter {
    constructor(data, includeNetTotalSale, includeCustomerName) {
        super(t('page.sales.export_summary_with_transactions_and_splits'), data, includeNetTotalSale,
            includeCustomerName);
    }

    getColumns() {
        return [
            ...super.getColumns(),
            SPLIT_NAME,
            SPLIT_AMOUNT
        ];
    }

    getData() {
        return this.data.reduce((result, item) => {
            result.push(item);
            item.transactions.forEach(tr => {
                result.push({
                    name: item.name,
                    ...tr,
                    transactionId: tr.id,
                    discountName: tr.discount ? tr.discount.name : '',
                    discountValue: formatDiscountValue(tr.discount),
                    createdAt: moment(tr.createdAt).format('l')
                });
                const {transactionCount, seats, activeSeats, discount, createdAt, id, gross, net, tax,
                    netTotalSale, splitTotal, ...transactionProps} = tr;
                tr.splits.forEach(split => {
                    result.push({
                        transactionId: id,
                        name: item.name,
                        ...transactionProps,
                        createdAt: moment(createdAt).format('l'),
                        splitName: split.name,
                        splitAmount: split.amount,
                    });
                });
            });
            return result;
        }, []);
    }
}

export class TrainingSummaryExporter extends BaseSummaryExporter {
    constructor(data, authUser) {
        super(data, authUser.superadmin)
    }

    getColumns() {
        return [CLASS_NAME, ...super.getColumns()];
    }
}

export class ToolboxSummaryExporter extends BaseSummaryExporter {
    constructor(data, authUser) {
        super(data, authUser.superadmin)
    }

    getColumns() {
        return [TOOLBOX_NAME, ...super.getColumns()];
    }
}

export class SplitSummaryExporter extends BaseSummaryExporter {
    constructor(data) {
        super(data, false)
    }

    getColumns() {
        return [SPLIT_GROUP, ...super.getColumns()];
    }

    getData() {
        const data = super.getData();
        data.forEach(d => d.splitGroup = d.name);
        return data;
    }
}

export class SplitItemSummaryExporter extends BaseSummaryExporter {
    constructor(data, split) {
        super(data, false);
        this.split = split;
    }

    getColumns() {
        return [SPLIT_GROUP, ITEM_NAME, ...super.getColumns()];
    }

    getData() {
        const data = super.getData();
        data.forEach(d => d.splitGroup = this.split.name);
        return data;
    }
}

export class TrainingTransactionExporter extends BaseTransactionDetailExporter {
    constructor(data, authUser) {
        super(data, authUser.superadmin, authUser.superadmin)
    }

    getColumns() {
        const columns = [CLASS_NAME, REGISTRATION_ID, ...super.getColumns()];
        columns.splice(columns.indexOf(SEATS_ACTIVE), 1);
        return columns;
    }
}

export class ToolboxTransactionExporter extends BaseTransactionDetailExporter {
    constructor(data, authUser) {
        super(data, authUser.superadmin, authUser.superadmin)
    }

    getColumns() {
        return [TOOLBOX_NAME, LICENSE_ID, ...super.getColumns()];
    }
}

export class SplitTransactionExporter extends BaseDetailExporter {
    constructor(data, authUser) {
        super(t('page.sales.export_summary_with_transactions'), data, false, authUser.superadmin);
        this.authUser = authUser;
    }

    getColumns() {
        return [SPLIT_GROUP, ITEM_NAME, LICENSE_OR_REGISTRATION_ID, ...super.getColumns()];
    }

    getData() {
        return this.data.reduce((result, split) => {
            const {name, ...splitProps} = split;
            result.push({
                splitGroup: name,
                ...splitProps,
            });
            const splitItems = [...split.toolboxes, ...split.trainings];
            const splitItemsExporter = new SplitItemTransactionExporter(splitItems, split, this.authUser);
            result = result.concat(splitItemsExporter.getData());
            return result;
        }, []);
    }
}

export class SplitItemTransactionExporter extends BaseDetailExporter {
    constructor(data, split, authUser) {
        super(t('page.sales.export_summary_with_transactions'), data, false, authUser.superadmin);
        this.split = split;
    }

    getColumns() {
        return [SPLIT_GROUP, ITEM_NAME, LICENSE_OR_REGISTRATION_ID, ...super.getColumns()];
    }

    getData() {
        return this.data.reduce((result, item) => {
            result.push({
                splitGroup: this.split.name,
                ...item
            });
            item.transactions.forEach(tr => {
                result.push({
                    splitGroup: this.split.name,
                    name: item.name,
                    licOrRegId: tr.licenseId || tr.registrationId,
                    ...transactionExportObject(tr)
                });
            });
            return result;
        }, []);
    }
}

export class TrainingTransactionSplitExporter extends BaseTransactionWithSplitDetailExporter {
    constructor(data, authUser) {
        super(data, authUser.superadmin, authUser.superadmin)
    }

    getColumns() {
        const columns = [CLASS_NAME, REGISTRATION_ID, ...super.getColumns()];
        columns.splice(columns.indexOf(SEATS_ACTIVE), 1);
        return columns;
    }
}

export class ToolboxTransactionSplitExporter extends BaseTransactionWithSplitDetailExporter {
    constructor(data, authUser) {
        super(data, authUser.superadmin, authUser.superadmin)
    }

    getColumns() {
        return [TOOLBOX_NAME, LICENSE_ID, ...super.getColumns()];
    }
}
