import React, {useState} from 'react';
import useAxios from '../../../hooks/use-axios';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useAuthUserContext } from '../../../context/auth-user-context';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import LoadingButton from '../../../components/loading-button';

export default function VerifyEmailDialog({onClose}) {
    const { t } = useTranslation();
    const { authUser } = useAuthUserContext();
    const [, sendVerification] = useAxios({
        url: `/api/users/${authUser.id}/email/verification`,
        method: 'PUT'
    }, {manual: true, showNotification: false});
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        sendVerification()
            .then(() => setSuccess(true))
            .catch(e => setError(e.response.data.message))
            .finally(() => setLoading(false));
    };
    let message = <>{t('page.profile.sending_email_verification') + ' '}<b>{authUser.email}</b></>;
    if(error) {
        message = <Alert severity="error">{error}</Alert>;
    } else if(success) {
        message = <Alert severity="success">{t('page.profile.sent_email_verification')}</Alert>;
    }
    return <Dialog open maxWidth="xs" fullWidth>
            <DialogTitle>{t('page.profile.email_verification')}</DialogTitle>
            <DialogContent dividers>
                <Box textAlign="center">{message}</Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton variant="contained" color="primary" disabled={loading || success}
                    onClick={handleSubmit} loading={loading}>
                    {t('general.text.send')}
                </LoadingButton>
                <Button color="primary" onClick={onClose}>
                    {t('general.text.close')}
                </Button>
          </DialogActions>
        </Dialog>
}