import React from 'react';

import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiTextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

export function fieldToAutocompleteSelectField({
        disabled, field,
        form: { isSubmitting, touched, errors, setFieldValue, setFieldTouched },
        ...props
    }) {

    const fieldError = getIn(errors, field.name),
        fieldTouched = getIn(touched, field.name),
        showError = fieldTouched && !!fieldError;

    const onChangeHandler = (e, val) => {
        field.onChange(e, val);

        if (!fieldTouched) {
            setFieldTouched(field.name, true);
        }

        setFieldValue(field.name, val);
    }

    return {
        ...props,
        ...field,
        onChange: onChangeHandler,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled || isSubmitting
    };
}

export function AutocompleteSelectField(props) {
    const {
        loading,
        variant, margin, fullWidth,
        error, helperText, label,
        ...otherProps
    } = fieldToAutocompleteSelectField(props);

    const textFieldProps = {
        variant, margin, fullWidth,
        error, helperText, label
    };

    return <Autocomplete
        {...otherProps}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            {...textFieldProps}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </>
                ),
            }}
          />
        )}
      />
}

AutocompleteSelectField.displayName = 'FormikMuiAutocompleteField';