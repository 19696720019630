import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PersonalInfoTab from './personal-info-tab';
import SecurityTab from './security-tab';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    tabs: {
        borderBottom: '1px solid #e8e8e8'
    }
}));

export default function ProfileView() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [tab, setTab] = useState('1');

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return <TabContext value={tab}>
        <Typography variant="h4">{t('page.users.profile')}</Typography>
        <TabList
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            variant="fullWidth"
            aria-label="profile tabs"
          >
            <Tab label={t('page.profile.personal_info')} value="1"/>
            <Tab label={t('page.profile.security')} value="2"/>
        </TabList>
        <Grid container>
            <Grid item md={3} lg={4} xs={false}></Grid>
            <Grid item xs={12} md={6} lg={4}>
                <TabPanel value="1"><PersonalInfoTab/></TabPanel>
                <TabPanel value="2"><SecurityTab/></TabPanel>
            </Grid>
        </Grid>
    </TabContext>
}