import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EntryViewWrapper from '../login/entry-view-wrapper';

import { LoadPanel } from '../../components/load-panel';
import useAxios from '../../hooks/use-axios';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%' // Fix IE 11 issue
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    loading: {
        verticalAlign: 'middle'
    }
}));

export default function ForgotPassword() {
    const classes = useStyles();
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [{ loading }, passwordRecoveryRequest] = useAxios('/api/auth/passwordrecovery/token', { manual: true });

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        passwordRecoveryRequest({ data: { email }, method: "POST" })
            .then(() => {
                enqueueSnackbar(t('page.forgot_password.notification.recovery_letter_sent'), {
                    variant: 'success',
                });
            });
    }

    return (
        <EntryViewWrapper>
            <LoadPanel wrapperComponent={Box} textAlign="center" showContent loading={loading}>
                <Typography component="h1" variant="h4">
                    {t('page.forgot_password.password_reset')}
                </Typography>
                <br/>
                <Typography component="h2" variant="body2">
                    {t('page.forgot_password.password_reset_instruction')}
                </Typography>
                <br />
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('general.text.email_address')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        {t('general.text.send')}
                    </Button>
                    <Link href="/login" variant="body2">
                        {t('page.login.signin')}
                    </Link>
                </form>
            </LoadPanel>
        </EntryViewWrapper>
    );
}