import React from 'react';
import { getIn } from 'formik';
import MuiTextField from '@material-ui/core/TextField';

export function fieldToTextField({ disabled, field, form: { isSubmitting, errors, touched, setFieldTouched }, ...props}) {
    const fieldError = getIn(errors, field.name),
        fieldTouched = getIn(touched, field.name),
        showError = fieldTouched && !!fieldError;

    const onChangeHandler = fieldTouched ? field.onChange : (e) => {
        setFieldTouched(field.name, true);
        field.onChange(e);
    }
    return {
        ...props,
        ...field,
        onChange: onChangeHandler,
        onBlur: null, // For touch prevent
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled || isSubmitting,
    };
}

export function TextField({ children, ...props }) {
    return <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>;
}

TextField.displayName = 'FormikMuiTextField';