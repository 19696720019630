import React, {useState, useEffect, useContext} from 'react';
import useAxios from 'axios-hooks'
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const AuthUserContext = React.createContext({});
AuthUserContext.displayName = 'AuthUserContext';

const AuthUserProvider = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [authUser, setAuthUser] = useState(null);
  const [isSigningIn, setSigningIn] = useState(true);
  const [isLoggingOut, setLoggingOut] = useState(false);
  const [isLogginedOut, setLogginedOut] = useState(false);

  const [, me] = useAxios('/api/auth/me', { manual: true });
  const [, sessions] = useAxios('/api/auth/sessions', { manual: true });
  const [, putUser] = useAxios({
    baseURL: '/api/users/',
    method: 'PUT'
  }, {manual: true});

  const _requestErrorsHandler = ({ status, statusText, data}) => {
    enqueueSnackbar(t('notification.general.request_fail', {
      status, statusText
    }) +  data && data.message && data.message.length
        ? String(data.message)
        : ''
    , {
      variant: 'error',
    });
  }

  const loadCurrentUser = () => {
    setSigningIn(true);

    me()
      .then(({data}) => {
        setAuthUser(data);
      })
      .finally(() => {
        setSigningIn(false);
      });

    return () => {
      setSigningIn(false);
    }
  };

  const clearUser = () => setAuthUser(null);

  const signIn = (email, password) => {
    setSigningIn(true);

    return sessions({
      data: {
        email, password
      },
      method: 'POST'
    }).then(me)
      .then((response) => {
        setAuthUser(response.data);
        return response;
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            enqueueSnackbar(t('page.login.notification.login_fail'), {
              variant: 'warning',
            });
            break;
          default:
            _requestErrorsHandler(error.response);
            break;
        }

        throw error;
      })
      .finally(() => {
        setSigningIn(false);
      })
  };

  const signOut = () => {
    setLoggingOut(true);

    return sessions({method: 'DELETE'})
      .then((response) => {
        setLogginedOut(true);
        clearUser();
        return response;
      })
      .catch((error) => {
        _requestErrorsHandler(error.response);
        throw error;
      })
      .finally(() => {
        setLoggingOut(false);
      })
  };

  const updateUser = (email, fullName) => {
    return putUser({
      url: authUser ? authUser.id : '',
      data: {email, fullName}
    })
    .then((response) => {
      setAuthUser(response.data);
      return response;
    })
    .catch((error) => {
      _requestErrorsHandler(error.response);
      throw error;
    });
  };

  const updatePassword = (oldPassword, password, beforeResetUser) => {
    beforeResetUser = beforeResetUser || (() =>{});
    return putUser({
      url: authUser ? authUser.id : '',
      data: {
        oldPassword, password,
        // api requires next fields
        email: authUser ? authUser.email : '',
        fullName: authUser ? authUser.fullName : ''
      }
    }).then((res) => beforeResetUser(res))
    .then(() => clearUser())
    .catch(e => {
      _requestErrorsHandler(e.response);
      throw e;
    })
  };

  useEffect(loadCurrentUser, []);

  return <AuthUserContext.Provider
    value={{
      authUser,
      isSigningIn,
      isLoggingOut,
      isLogginedOut,
      signIn,
      signOut,
      updateUser,
      updatePassword,
      clearUser
    }}
  >
    {props.children}
  </AuthUserContext.Provider>
}

export {
  AuthUserProvider
};

export const useAuthUserContext = () => useContext(AuthUserContext);
