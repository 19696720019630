import React from 'react';

import * as Yup from 'yup';

import { Formik, Form, Field } from 'formik';
import { TextField } from '../../components/text-field';
import { CheckboxField } from '../../components/checkbox-field';
import { LoadPanel } from '../../components/load-panel';
import { AutocompleteSelectField } from '../../components/autocomplete-select-field';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { executeAsPromise } from '../../utils';

import { useTranslation } from 'react-i18next';
import useAxios from '../../hooks/use-axios';

import L from '../../../locale';

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(255, L.t('general.text.validation.max_characters', {
            count: 255
        }))
        .required(L.t('general.text.validation.required')),
    description: Yup.string(),
    percent: Yup.number()
        .moreThan(0, L.t('general.text.validation.value_must_be_more', {
            value: 0
        }))
        .max(100, L.t('general.text.validation.value_must_be_less_or_equals', {
            value: 100
        }))
        .required(L.t('general.text.validation.required')),
    group: Yup.string().nullable()
        .required(L.t('general.text.validation.required')),
    users: Yup.array(),
    active: Yup.boolean()
        .required(L.t('general.text.validation.required')),
});

export const dialogTypes = Object.freeze({
    EDIT: "edit",
    NEW: "new"
});

export default function SplitFormDialog({onSave, onClose, formData, type}) {
    const { t } = useTranslation();

    const [{
        data: groupsList,
        loading: loadingGroupsList
    }] = useAxios(
        '/api/groups'
    )

    const [{
        data: usersList,
        loading: loadingUsersList
    }] = useAxios(
        '/api/users'
    )

    const fieldProps = {
        variant: "outlined",
        margin: 'dense',
        fullWidth: true,
    };


    const onSubmitHandler = (values, { setSubmitting }) => {
        executeAsPromise(onSave(values, type))
            .finally(() => setSubmitting(false));
    };

    const optionSelector = (option, value) => option.id === value.id;

    return <Formik
        validateOnBlur={false}
        validateOnMount={true}
        initialValues={{
            name: "",
            description: "",
            percent: "",
            group: null,
            users: [],
            active: true,
            ...formData
        }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmitHandler}
    >
        {({ submitForm, isSubmitting, isValid }) => (
            <Dialog open maxWidth="xs" fullWidth>
                <DialogTitle>{
                    type === dialogTypes.EDIT
                        ? 'Edit split'
                        : 'New split'
                }</DialogTitle>
                <DialogContent dividers>
                    <LoadPanel loading={isSubmitting} showContent={true}>
                        <Form>
                            <Field component={TextField} autoFocus type="text" {...fieldProps}
                                name="name"
                                label={t('page.splits.split_id') + " *"} />
                            <Field component={TextField} type="text" multiline {...fieldProps}
                                name="description"
                                label={t('general.text.description')}/>
                            <Field component={TextField} type="number" {...fieldProps}
                                name="percent"
                                label={t('page.splits.split_precentage') + " *"} />
                            <Field component={AutocompleteSelectField} {...fieldProps} limitTags={2}
                                getOptionSelected={optionSelector}
                                getOptionLabel={group => group.name}
                                options={groupsList || []} loading={loadingGroupsList}
                                name="group"
                                label={t('general.text.group') + " *"}/>
                            <Field component={AutocompleteSelectField} {...fieldProps} multiple limitTags={2}
                                getOptionSelected={optionSelector}
                                getOptionLabel={user => user.fullName}
                                options={usersList || []} loading={loadingUsersList}
                                name="users"
                                label={t('general.text.users')}/>
                            <Field component={CheckboxField} type="checkbox" color="primary"
                                name="active"
                                label={t('page.splits.active')} />
                        </Form>
                    </LoadPanel>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" disabled={!isValid || isSubmitting || loadingGroupsList || loadingUsersList} onClick={submitForm}>{
                        type === dialogTypes.EDIT
                            ? t('general.text.save')
                            : t('general.text.create')
                    }</Button>
                    <Button onClick={onClose} color="primary">{t('general.text.cancel')}</Button>
                </DialogActions>
            </Dialog>
        )}
    </Formik>
}

export function NewSplitFormDialog(props) {
    return <SplitFormDialog {...props} type={dialogTypes.NEW}/>
}

export function EditSplitFormDialog(props) {
    return <SplitFormDialog {...props} type={dialogTypes.EDIT}/>
}