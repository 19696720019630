import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locale_En from './Locale_En';
import moment from 'moment';

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: 'en',
        keySeparator: false,
        resources: {
            en: {
                translation: Locale_En
            }
        }
});

i18n.on('languageChanged', function (lng) {
    moment.locale(lng);
});

export default i18n;