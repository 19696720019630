import React, {useState} from 'react';

import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Group from './list-group-item'
import { NewGroupFormDialog, EditGroupFormDialog } from './group-form-dialog';
import GroupRemoveDialog from './group-remove-dialog';

import { LoadPanel } from '../../components/load-panel';
import useAxios from '../../hooks/use-axios';

const useStyles = makeStyles((theme) => ({
    cards: {
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    }
}));

const AddGroupButton = props =>
    <IconButton {...props}>
        <AddCircleIcon color="primary" fontSize="large" />
    </IconButton>

const prepareGroupDataForForm = ({ nestedGroups, name, description, toolboxes, trainings }) => {
    return {
        name,
        description,
        toolboxes,
        trainings,
        groups: nestedGroups
    }
}

const prepareGroupForRequest = ({ groups, toolboxes, trainings, ...otherGroupData }) => {
    return {
        ...otherGroupData,
        groups: (groups || []).map(group => group.id),
        toolboxes: (toolboxes || []).map(tb => tb.id),
        trainings: (trainings || []).map(tr => tr.id)
    }
}

const sort = (array) => array.sort((a,b) => a.name.localeCompare(b.name, 'en', { numeric: true }));

export default function Groups() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [showCreateGroupDialog, setCreateGroupDialogVisibility] = useState(false);
    const [editableGroup, selectGroupForEdit] = useState(null);
    const [removableGroup, selectGroupForRemove] = useState(null);

    const [{ data, loading, error }, refetchGroupsList ] = useAxios(
        '/api/groups'
    );

    const [, createGroupRequest] = useAxios({
        url: '/api/groups',
        method: 'POST'
    }, { manual: true });

    const [, updateGroupRequest] = useAxios({
        method: 'PUT'
    }, { manual: true });

    const [, removeGroupRequest] = useAxios({
        method: 'DELETE'
    }, { manual: true });

    const createGroup = (groupData) => {
        return createGroupRequest({
            data: prepareGroupForRequest(groupData),
        }).then(() => {
            refetchGroupsList()
            setCreateGroupDialogVisibility(false);
            enqueueSnackbar(`Saved!`, {
                variant: 'success',
            });
        });
    }

    const updateGroup = (groupData) => {
        return updateGroupRequest({
            data: prepareGroupForRequest(groupData),
            url: `/api/groups/${editableGroup.id}`,
        }).then(() => {
            refetchGroupsList();
            selectGroupForEdit(null);
            enqueueSnackbar(`Saved!`, {
                variant: 'success',
            });
        });
    }

    const onRemoveGroupHandler = () => {
        return removeGroupRequest({
            url: `/api/groups/${removableGroup.id}`,
        }).then(() => {
            refetchGroupsList();
            enqueueSnackbar(`Removed!`, {
                variant: 'success',
            });
            selectGroupForRemove(null);
        });
    }

    const isImmutableGroup = group => group
        && group.name.toLowerCase() === "AllToolboxesAndTrainings".toLowerCase();

    return (
        <>
            <AddGroupButton onClick={() => setCreateGroupDialogVisibility(true)}/>
            <LoadPanel showContent loading={loading} error={error || undefined}>
                <div className={classes.cards}>
                    {data && sort(data).map(group =>
                        <Group isImmutable={isImmutableGroup(group)} data={group} key={group.id}
                            onEditBtnClick={() => selectGroupForEdit(group)}
                            onDeleteBtnClick={() => selectGroupForRemove(group)}/>
                    )}
                </div>
            </LoadPanel>
            {showCreateGroupDialog &&
                <NewGroupFormDialog
                    onClose={() => setCreateGroupDialogVisibility(false)}
                    onSave={createGroup}/>
            }
            {editableGroup &&
                <EditGroupFormDialog
                    isImmutableGroup={isImmutableGroup(editableGroup)}
                    formData={prepareGroupDataForForm(editableGroup)}
                    onClose={() => selectGroupForEdit(null)}
                    onSave={updateGroup}/>
            }
            {removableGroup &&
                <GroupRemoveDialog
                    group={removableGroup}
                    onClose={() => selectGroupForRemove(null)}
                    onRemove={onRemoveGroupHandler}/>
            }
        </>
    );
}
