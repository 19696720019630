import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItemLink from './list-item-link';
import Content from './content';
import NavBar from './nav-bar';
import AppBar from './app-bar';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useAuthUserContext } from '../../context/auth-user-context';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    list: {
        color: 'white'
    },
    drawerContainer: {
        overflow: 'auto'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    }
}));

export default function BaseView(props) {
    const classes = useStyles();
    const {authUser} = useAuthUserContext();
    const { t } = useTranslation();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className={classes.drawerContainer}>
            <Toolbar/>
            <Divider/>
            <List className={classes.list}>
                <ListItemLink primary={t('navbar.list_item.sales_history')}>
                    <ListItemLink to="/sales/toolboxes" primary={t('navbar.list_item.sales_history_by_toolbox')}/>
                    <ListItemLink to="/sales/trainings" primary={t('navbar.list_item.sales_history_by_class')}/>
                    <ListItemLink to="/sales/splits" primary={t('navbar.list_item.sales_history_by_split')}/>
                </ListItemLink>
                {authUser.superadmin && <ListItemLink to="/groups" primary={t('navbar.list_item.manage_tml_groups')}/>}
                {authUser.superadmin && <ListItemLink to="/splits" primary={t('navbar.list_item.manage_splits')}/>}
                {authUser.superadmin && <ListItemLink to="/users" primary={t('navbar.list_item.manage_users')}/>}
            </List>
        </div>
    );
    return (
        <div className={classes.root}>
            <AppBar>
                <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}
                    className={classes.menuButton}>
                    <MenuIcon/>
                </IconButton>
            </AppBar>
            <NavBar onClose={handleDrawerToggle} open={mobileOpen}>
                {drawer}
            </NavBar>
            <Content>
                {props.children}
            </Content>
        </div>
    );
}