import React from 'react';

import useAxios from '../../hooks/use-axios';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import {TextField} from '../../components/text-field';
import {LoadPanel} from '../../components/load-panel';
import {AutocompleteSelectField} from '../../components/autocomplete-select-field';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { executeAsPromise } from '../../utils';

import L from '../../../locale';
import { useTranslation } from 'react-i18next';

const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(L.t('general.text.validation.required')),
    description: Yup.string(),
    toolboxes: Yup.array().when('trainings', (trainings, schema) =>
        !trainings || trainings.length === 0
            ? schema.required(L.t('general.text.validation.required'))
            : schema
    ),
    trainings: Yup.array().when('toolboxes', (toolboxes, schema) =>
        !toolboxes || toolboxes.length === 0
            ? schema.required(L.t('general.text.validation.required'))
            : schema
    )
}, ['toolboxes', 'trainings']);

export const dialogTypes = Object.freeze({
    EDIT: "edit",
    NEW: "new"
});

export default function GroupFormDialog({ onSave, onClose, formData, type, isImmutableGroup }) {
    const { t } = useTranslation();

    const [{
        data: groupsList,
        loading: loadingGroupsList
    }] = useAxios(
        '/api/groups'
    )

    const [{
        data: toolboxesList,
        loading: loadingToolboxesList
    }] = useAxios(
        '/api/toolboxes'
    )

    const [{
        data: trainingList,
        loading: loadingTrainingList
    }] = useAxios(
        '/api/trainings'
    )

    const fieldProps = {
        variant: "outlined",
        margin: 'dense',
        fullWidth: true,
    };

    const onSubmitHandler = (values, { setSubmitting }) => {
        executeAsPromise(onSave(values, type))
            .finally(() => setSubmitting(false));
    };

    const optionSelector = (option, value) => option.id === value.id;
    const getOptionLabel = (option) => option.name;

    return <Formik
        validateOnBlur={false}
        validateOnMount={true}
        initialValues={{
            name: "",
            description: "",
            toolboxes: [],
            trainings: [],
            groups: [],
            ...formData
        }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmitHandler}
    >
        {({ submitForm, isSubmitting, isValid }) => (
            <Dialog open maxWidth="xs" fullWidth>
                <DialogTitle>{
                    type === dialogTypes.EDIT
                        ? t('page.groups.edit_group')
                        : t('page.groups.new_group')
                }</DialogTitle>
                <DialogContent dividers>
                    <LoadPanel loading={isSubmitting} showContent={true}>
                        <Form>
                            <Field component={TextField} type="text" autoFocus {...fieldProps}
                                disabled={isImmutableGroup}
                                name="name"
                                label={t('page.groups.group_id') + ' *'} />
                            <Field component={TextField} type="text" multiline {...fieldProps}
                                name="description"
                                label={t('general.text.description')} />
                            <Field component={AutocompleteSelectField} {...fieldProps} multiple limitTags={2}
                                name="toolboxes"
                                label={t('page.groups.toolboxes') + ' *'}
                                getOptionSelected={optionSelector}
                                getOptionLabel={getOptionLabel}
                                options={toolboxesList || []} loading={loadingToolboxesList} />
                            <Field component={AutocompleteSelectField} {...fieldProps} multiple limitTags={2}
                                name="trainings"
                                label={t('page.groups.trainings') + ' *'}
                                getOptionSelected={optionSelector}
                                getOptionLabel={getOptionLabel}
                                options={trainingList || []} loading={loadingTrainingList} />
                            <Field component={AutocompleteSelectField} {...fieldProps} multiple limitTags={2}
                                name="groups"
                                label={t('general.text.groups')}
                                getOptionSelected={optionSelector}
                                getOptionLabel={getOptionLabel}
                                options={groupsList || []} loading={loadingGroupsList} />
                        </Form>
                    </LoadPanel>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" disabled={!isValid || isSubmitting || loadingGroupsList || loadingToolboxesList} onClick={submitForm}>{
                        type === dialogTypes.EDIT
                            ? t('general.text.save')
                            : t('general.text.create')
                    }</Button>
                    <Button onClick={onClose} color="primary">{t('general.text.cancel')}</Button>
                </DialogActions>
            </Dialog>
        )}
    </Formik>
}

export function NewGroupFormDialog(props) {
    return <GroupFormDialog {...props} type={dialogTypes.NEW} />
}

export function EditGroupFormDialog(props) {
    return <GroupFormDialog {...props} type={dialogTypes.EDIT} />
}