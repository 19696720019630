import React, { useState, useEffect } from 'react';
import TextField from "@material-ui/core/TextField";
import { DateRangePicker as RangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';

const fieldProps = {
    variant: "outlined",
    margin: 'dense'
};

/* SUPER MEGA HACK to allow to select the same day in picker
    It requires to customize "isBefore" function of dataAdapter, which is called in "isRangeValid" function
        and customize behavior in 3 places to control by our side:
    @1) File "@material-ui/pickers/lib/src/_helpers/date-utils.ts",
        "validateDateRange" function,
        Problem:
        <code>
            if (!isRangeValid(utils, [utils.date(start), utils.date(end)])) {
                return ['invalidRange', 'invalidRange'];
            }
        </code>
        isRangeValid does not return "true"
        Solution:
            override "utils.date" function(where utils is "dataAdapter")
            and check that this function is called two times,
            so we can add a hint for "isBefore" function to return "true" in "isRangeValid" function.
            The hint is a property that is called "checkForEqualInIsBefore".
    @2) File @material-ui/pickers/lib/src/DateRangePicker/DateRangePickerView.tsx"
        "handleChange" callback,
        Problem:
            'calculateRangeChange' must return [start, end] for the same day,
            it means "isBefore" called inside of it must return "false"
        Solution:
            every onChange we set "checkSameDate" prop of startDate to check it in "isBefore",
            return "false" and set "nextIsBeforeReturnTrue" property for next problem.
    @3) File @material-ui/pickers/lib/src/DateRangePicker/DateRangePickerView.tsx"
        "handleChange" callback,
        Problem:
            <code>
                const isFullRangeSelected =
                    currentlySelectingRangeEnd === 'end' && isRangeValid(utils, newRange);
              </code>
          This code must return "true" to call callback with updated values and close picker.
          Solution:
          Check "nextIsBeforeReturnTrue" is set in previous "isBefore" call and return "true"
*/
const oldIsBefore = MomentUtils.prototype.isBefore;
const oldDate = MomentUtils.prototype.date;
MomentUtils.prototype.isBefore = function(date, value) {
    if(date.valueOf && value.valueOf) {
        if(date.valueOf() === value.valueOf()) {
            // Solution for @2)
            if(value.checkSameDate) {
                this.nextIsBeforeReturnTrue = true;
                this.checkSameDate = false;
                return false;
            }
            // Solution for @3)
            if(this.nextIsBeforeReturnTrue) {
                this.nextIsBeforeReturnTrue = false;
                return true;
            }
            if(this.checkForEqualInIsBefore) {
                this.checkForEqualInIsBefore = false;
                return true;
            }
        }
    }
    return oldIsBefore(date, value);
};

// Solution for @1)
MomentUtils.prototype.date = function(value) {
    const t = Date.now();
    if(this.prevDateCallTs && (t - this.prevDateCallTs) < 5) {
        this.checkForEqualInIsBefore = true;
    }
    this.prevDateCallTs = t;
    return oldDate.call(this, value);
}

export default function DateRangePicker({value, onChange, ...otherProps}) {
    const { t } = useTranslation();
    const [range, setRange] = useState(value);

    useEffect(() => setRange(value), [value]);

    const handleChange = (v) => {
        const [s] = v;
        s.checkSameDate = true;
        setRange(v);
    }

    return (
        <LocalizationProvider dateAdapter={MomentUtils}>
            <RangePicker
                {...otherProps}
                allowSameDateSelection
                onAccept={onChange}
                startText={t('page.sales.start')}
                endText={t('page.sales.end')}
                value={range}
                onChange={handleChange}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <TextField {...startProps} {...fieldProps} helperText=""/>
                        <DateRangeDelimiter> -> </DateRangeDelimiter>
                        <TextField {...endProps} {...fieldProps} helperText=""/>
                    </React.Fragment>
                )}
            />
        </LocalizationProvider>
    );
}