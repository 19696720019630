import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SplitsTable from './splits-table';
import DetailsDatatable from './details-datatable';

const useStyles = makeStyles((theme) => ({
    topRow: {
        "& > td": {
            borderTop: `6px solid ${theme.palette.grey[200]}`,
        }
    },
    bottomRow: {
        backgroundColor: theme.palette.grey[50],
        "& > td": {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
    },
    splitsTableWrapper: {
        padding: '0!important'
    }
}));

function DetailsTableRow({data, columns, showNetTotalSale}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [, splits, ...cells] = data;
    const columnOffsetIndex = data.length - cells.length;
    const displayedCells = cells.filter((cell, ind) => {
        const column = columns[ind + columnOffsetIndex];
        return !column.options || column.options.display !== false;
    });

    return <>
        <TableRow className={classes.topRow}>
            {displayedCells.map((val, ind) => {
                const column = columns[ind + columnOffsetIndex];
                const cellProps = (column.options && column.options.setCellProps
                    && column.options && column.options.setCellProps()) || {};
                return <TableCell key={ind} {...cellProps}>
                    {val}
                </TableCell>
            })}
        </TableRow>
        <TableRow className={classes.bottomRow}>
            <TableCell colSpan={displayedCells.length - 3}/>
            <TableCell align="center"><b>{t('page.sales.splits')}:</b></TableCell>
            <TableCell colSpan={2} className={classes.splitsTableWrapper}>
                <SplitsTable data={splits}/>
            </TableCell>
        </TableRow>
    </>
}

export default function DetailsTable({data, columns, title, exportButton}) {
    return <DetailsDatatable
        title={title}
        data={data}
        columns={columns}
        customToolbar={() => exportButton.render()}
        customRowRender={(data) => {
            const id = data[0];
            return <DetailsTableRow key={id} data={data} columns={columns}/>
        }}
    />
}