import React from 'react';
import {Link} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import logo from '../../../img/logo.png';
import Box from '@material-ui/core/Box';
import ProfileButton from './buttons/profile';
import LanguageButton from './buttons/language';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#343a40',
        zIndex: theme.zIndex.drawer + 1
    },
    logoTitle: {
        color: 'white',
        marginLeft: theme.spacing(2)
    },
    buttons: {
        flex: 1
    }
}));

export default function MainAppBar(props) {
    const classes = useStyles();

    return <AppBar position="fixed" className={classes.root}>
        <Toolbar>
            {props.children}
            <Link to="/"><Avatar variant="square" src={logo}></Avatar></Link>
            <Hidden only="xs">
                <Typography variant="h6" className={classes.logoTitle}>Rockpile Solutions</Typography>
            </Hidden>
            <Box textAlign="right" className={classes.buttons}>
                <LanguageButton/>
                <ProfileButton/>
            </Box>
        </Toolbar>
    </AppBar>
}