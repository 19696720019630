import React, { useMemo } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DateRangePicker from './date-range-picker';
import useDateRange from '../../hooks/use-date-range';
import TableLocal from '../../../locale/mui-datatables-localization';

const muiTableTheme = createMuiTheme({
    overrides: {
        // overriding print styles to include header with dates
        MUIDataTableToolbar: {
            //fix default @media print display none
            root: {
                '@media print': {
                    display: 'flex'
                }
            },
            fullWidthLeft: {
                '@media print': {
                    flex: '1 1 auto'
                }
            },
            actions: {
                '@media print': {
                    display: 'none'
                },
            },
            //fix [theme.breakpoints.down('xs')] @media print display none/block
            '@media (max-width:599.95px)': {
                root: {
                    display: 'flex',
                    '@media print': {
                        display: 'flex'
                    }
                }
            }
        },
        MUIDataTableHeadCell: {
            root: {
                paddingBottom: 32
            },
            sortLabelRoot: {
                height: 'auto'
            }
        },
        MuiTableCell: {
            alignRight: {
                '& > span': {
                    justifyContent: 'flex-end'
                }
            }
        },
        MUIDataTable: {
            responsiveBase: {
                '@media print': {
                    overflow: 'hidden'
                }
            }
        }
    }
});

const BaseSalesDatatable = ({options={}, ...props}) => {
    const newOptions = {
        ...options,
        textLabels: {
            ...TableLocal,
            ...((options && options.textLabels) || {})
        }
    };

    return <MuiThemeProvider theme={muiTableTheme}>
            <MUIDataTable {...props} options={newOptions}/>
        </MuiThemeProvider>
}


const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'baseline'
    },
    title: {
        marginRight: theme.spacing(1)
    },
    '@global': {
        '.printMainOnly': {
            '& > div > table .printOnly': {
                display: 'none'
            }
        },
        '.printOnly': {
            display: 'none',
            '@media print': {
                display: 'unset'
            }
        }
    }
}));

export default function Datatable({title, data, columns, ...options}) {
    return (
        <BaseSalesDatatable
            className="printMainOnly"
            title={title}
            data={data}
            columns={columns}
            options={{
                expandableRowsHeader: false,
                selectableRows: 'none',
                filter: false,
                search: false,
                download: false,
                viewColumns: false,
                expandableRows: true,
                responsive: 'standard',
                ...options
            }}
        />
    );
}

export function useDatatableDateRangeTitle(headerLabel, printHeaderLabel) {
    const classes = useStyles();
    const [dateRange, setDateRange, apiDateRange] = useDateRange();
    const [start, end] = dateRange;

    const printHeader = useMemo(() => (
        <Box className="printOnly" textAlign="center">
            <Typography variant="h5" component="h5">{printHeaderLabel}</Typography>
            <Typography variant="body1" component="h6">
                {start.format('YYYY-MM-DD')} - {end.format('YYYY-MM-DD')}
            </Typography>
        </Box>
    ), [start, end, printHeaderLabel]);

    const title = useMemo(() => (
            <div>
            <Box className="datatables-noprint">
                <div className={classes.header}>
                    <Typography variant="h6" component="span" className={classes.title}>
                        {headerLabel}
                        </Typography>
                    <DateRangePicker value={dateRange} onChange={setDateRange}/>
                </div>
            </Box>
            {printHeader}
        </div>
    ), [classes.header, classes.title, headerLabel, dateRange, setDateRange, printHeader]);
    return [...apiDateRange, title, printHeader];
}