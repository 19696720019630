import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(2)
    }
}));


export default function Content(props) {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <Toolbar/>
            {props.children}
        </main>
    );
}