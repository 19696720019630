function escapeDangerousCSVCharacters(data) {
  if (typeof data === 'string') {
    // Places single quote before the appearance of dangerous characters if they
    // are the first in the data string.
    return data.replace(/^\+|^-|^=|^@/g, "'$&");
  }
  return data;
}


function buildCSV(columns, data) {
  const replaceDoubleQuoteInString = columnData =>
    typeof columnData === 'string' ? columnData.replace(/"/g, '""') : columnData;

  const filterdColumns = columns.filter(col => (!col.options || col.options.download !== false));

  const buildHead = columns => {
    return (
      filterdColumns
        .reduce(
          (result, column) =>
              result + '"' + escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label)) + '",',
          '',
        )
        .slice(0, -1) + '\r\n'
    );
  };
  const CSVHead = buildHead(columns);

  const buildBody = data => {
    if (!data.length) return '';
    return data
      .reduce(
        (soFar, row) =>
          soFar +
          '"' +
          filterdColumns
            .map(column => escapeDangerousCSVCharacters(replaceDoubleQuoteInString(row[column.name])))
            .join('","') +
          '"\r\n',
        '',
      )
      .trim();
  };
  const CSVBody = buildBody(data);

  return `${CSVHead}${CSVBody}`.trim();
}

function downloadCSV(csv, filename) {
  const blob = new Blob([csv], { type: 'text/csv' });

  /* taken from react-csv */
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const dataURI = `data:text/csv;charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;
    const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.setAttribute('href', downloadURI);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function createCSVDownload(columns, data, filename) {
  const csv = buildCSV(columns, data);
  downloadCSV(csv, filename);
}

export {
  createCSVDownload,
  buildCSV,
  downloadCSV
};