import React from 'react';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from '../../components/text-field';
import { LoadPanel } from '../../components/load-panel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { executeAsPromise } from '../../utils';
import { useAuthUserContext } from '../../context/auth-user-context';

import { useTranslation } from 'react-i18next';

const createCommonValidationSchema = (t) => Yup.object({
  fullName: Yup.string()
    .min(2, t('general.text.validation.too_short'))
    .max(50, t('general.text.validation.too_long'))
    .required(t('general.text.validation.required')),
  email: Yup.string()
    .email(t('general.text.validation.invalid_email'))
    .required(t('general.text.validation.required')),
});

const createValidateSchemaForNewUser = (t) => createCommonValidationSchema(t).shape({
  password: Yup.string()
    .min(6, t('general.text.validation.password_must_be_more_or_equals', { count: 6 }))
    .required(t('general.text.validation.required')),
})

const createValidateSchemaForExistUser = (t) => createCommonValidationSchema(t).shape({
  password: Yup.string()
    .min(6, t('general.text.validation.password_must_be_more_or_equals', { count: 6 }))
    .when('oldPassword', {
      is: Boolean,
      then: Yup.string()
        .min(6, t('general.text.validation.password_must_be_more_or_equals', { count: 6 }))
        .required(t('general.text.validation.required')),
    }),
  oldPassword: Yup.string()
    .when('password', {
      is: Boolean,
      then: Yup.string().required(t('general.text.validation.required')),
    }),
}, ['password', 'oldPassword']);

const createValidateSchemaForNotSelfUser = (t) => createCommonValidationSchema(t).shape({
  password: Yup.string()
  .min(6, t('general.text.validation.password_must_be_more_or_equals', { count: 6 }))
})

export const dialogTypes = Object.freeze({
  EDIT: "edit",
  NEW: "new"
});

export default function UserFormDialog({ onSave, onClose, formData, type, userId }) {
  const { t } = useTranslation();
  const { authUser } = useAuthUserContext();

  const fieldProps = {
    variant: "outlined",
    margin: 'dense',
    fullWidth: true,
  };

  const onSubmitHandler = (values, { setSubmitting }) => {
    executeAsPromise(onSave(values, type))
      .finally(() => setSubmitting(false));
  };

  return <Formik
    validateOnBlur={false}
    validateOnMount={true}
    initialValues={{
      fullName: "",
      email: "",
      oldPassword: "",
      password: "",
      ...formData
    }}
    validationSchema={
        type === dialogTypes.NEW
          ? createValidateSchemaForNewUser(t)
          : authUser.id === userId
            ? createValidateSchemaForExistUser(t)
            : createValidateSchemaForNotSelfUser(t)
      }
      onSubmit={onSubmitHandler}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Dialog open maxWidth="xs" fullWidth>
          <DialogTitle>{
            type === dialogTypes.EDIT
              ? t('page.users.edit_user')
              : t('page.users.new_user')
          }</DialogTitle>
          <DialogContent dividers>
            <LoadPanel loading={isSubmitting} showContent={true}>
              <Form>
                <Field component={TextField} type="text" {...fieldProps}
                  name="fullName"
                  label={t('page.users.user_name') + " *"} />
                <Field component={TextField} type="email" {...fieldProps}
                  name="email"
                  label={t('page.users.email_address') + " *"}/>
                {(type === dialogTypes.EDIT && authUser.id === userId) &&
                  <Field component={TextField} type="password"  {...fieldProps}
                    name="oldPassword"
                    label={t('general.text.current_password')} />
                }
                <Field component={TextField} type="password"  {...fieldProps}
                  name="password"
                  label={
                    type === dialogTypes.EDIT || authUser.id === userId
                      ? t('general.text.new_password')
                      : t('general.text.password') + " *"
                  }
                />
              </Form>
            </LoadPanel>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" disabled={!isValid || isSubmitting} onClick={submitForm}>{
              type === dialogTypes.EDIT
              ? t('general.text.save')
              : t('general.text.add')
            }</Button>
            <Button onClick={onClose} color="primary">{t('general.text.cancel')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
}

export function NewUserFormDialog(props) {
  return <UserFormDialog {...props} type={dialogTypes.NEW}/>
}

export function EditUserFormDialog(props) {
  return <UserFormDialog {...props} type={dialogTypes.EDIT}/>
}