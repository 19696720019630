import { useState } from 'react';
import moment from 'moment';

function transformDateRangeToAPI([start, end]) {
    return [start.clone(), end.clone().add(1,'day')];
}

export default function useDateRange() {
    const initial = [
        moment().startOf('month'),
        moment().startOf('day')
    ];
    const [dateRangePickerValue, setDateRangePickerValue] = useState(initial);
    const [dateRangeApiValue, setDateRangeApiValue] = useState(transformDateRangeToAPI(initial));

    const handleDatePickerChange = (value) => {
        setDateRangePickerValue(value);
        setDateRangeApiValue(transformDateRangeToAPI(value));
    }

    return [dateRangePickerValue, handleDatePickerChange, dateRangeApiValue];
}