import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import {createCSVDownload} from '../../utils/csv';

const useStyles = makeStyles((theme) => ({
    toolbarButtonHover: {
        '&:hover': {
            color: theme.palette.primary.main,
        }
    }
}));

function ExportButtonComponent({children}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (cb, ind) => (e) => {
        cb && cb(e, ind);
        handleClose();
    }

    return <>
        <Tooltip title={t('general.text.download_csv')}>
            <IconButton aria-controls="sales_download_button" aria-haspopup="true"
                onClick={handleClick} aria-label={t('general.text.download_csv')}
                classes={{ root: classes.toolbarButtonHover }}>
                <DownloadIcon />
            </IconButton>
        </Tooltip>
        <Menu id="sales_download_button" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}
            onClose={handleClose}>
            {children.filter(Boolean).map((child, ind) => React.cloneElement(child,
                {
                    key: ind,
                    ...child.props,
                    onClick: handleItemClick(child.props.onClick, ind)
                }))
            }
        </Menu>
    </>
}

export default class ExportButton {
    constructor(exprotFileName, exporters) {
        this.filename = exprotFileName;
        this.exporters = exporters;
    }

    download = (e, ind) => {
        const exporter = this.exporters[ind];
        createCSVDownload(exporter.getColumns(), exporter.getData(), this.filename);
    }

    render() {
        return <ExportButtonComponent>
            {this.exporters.map((exporter, ind) =>
            <MenuItem key={ind} onClick={this.download}>{exporter.name}</MenuItem>)}
        </ExportButtonComponent>
    }
}