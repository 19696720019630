import React from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import TableLocal from '../../../locale/mui-datatables-localization';

const muiTableTheme = createMuiTheme({
    overrides: {
        // overriding print styles to include header with dates
        MUIDataTableToolbar: {
            //fix default @media print display none
            root: {
                '@media print': {
                    display: 'flex',
                    paddingBottom: '10px'
                }
            },
            fullWidthLeft: {
                '@media print': {
                    flex: '1 1 auto'
                }
            },
            actions: {
                '@media print': {
                    display: 'none'
                },
            },
            //fix [theme.breakpoints.down('xs')] @media print display none/block
            '@media (max-width:599.95px)': {
                root: {
                    display: 'flex',
                    '@media print': {
                        display: 'flex'
                    }
                }
            }
        },
        MUIDataTableHeadCell: {
            root: {
                '@media print': {
                    padding: '0 5px'
                }
            },
            sortLabelRoot: {
                height: 'auto'
            }
        },
        MuiTableCell: {
            alignRight: {
                '& > span': {
                    justifyContent: 'flex-end'
                }
            },
            root: {
                '@media print': {
                    padding: '6px 10px 6px 5px !important'
                }
            }
        },
        MUIDataTable: {
            responsiveBase: {
                '@media print': {
                    overflow: 'hidden'
                }
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    detailsTable: {
        "&  table": {
            borderCollapse: 'separate',
        }
    }
}));

const Datatable = ({options={}, ...props}) => {
    const newOptions = {
        ...options,
        textLabels: {
            ...TableLocal,
            ...((options && options.textLabels) || {})
        }
    };

    return <MuiThemeProvider theme={muiTableTheme}>
            <MUIDataTable {...props} options={newOptions}/>
        </MuiThemeProvider>
}

export default function DetailsDatatable({title, data, columns, ...options}) {
    const classes = useStyles();
    return <Datatable
        className={classes.detailsTable}
        title={title}
        data={data}
        columns={columns}
        options={{
            sort: false,
            filter: false,
            search: false,
            download: false,
            viewColumns: false,
            pagination: false,
            selectableRows: 'none',
            elevation: 0,
            responsive: 'standard',
            setTableProps: () => ({size: 'small'}),
            ...options,
        }}
    />
}