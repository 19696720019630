import React, {useState} from 'react';

import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import {LoadPanel} from '../../components/load-panel';
import Split from './list-split-item'
import {NewSplitFormDialog, EditSplitFormDialog} from './split-form-dialog'
import SplitRemoveDialog from './split-remove-dialog'
import useAxios from '../../hooks/use-axios';

const useStyles = makeStyles((theme) => ({
    cards: {
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    }
}));

const AddSplitButton = props =>
    <IconButton {...props}>
        <AddCircleIcon color="primary" fontSize="large" />
    </IconButton>

const prepareSplitDataForForm = ({ active, name, description, percent, users, group }) => {
    return {
        active,
        name,
        description,
        percent,
        users,
        group
    }
}

const prepareSplitForRequest = ({ group, users, ...otherSplitData }) => {
    return {
        ...otherSplitData,
        group: group ? group.id: null,
        users: (users || []).map(user => user.id)
    }
}

export default function Splits() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [showCreateSplitDialog, setCreateSplitDialogVisibility] = useState(false);
    const [editableSplit, selectSplitForEdit] = useState(null);
    const [removableSplit, selectSplitForRemove] = useState(null);

    const [{ data, loading, error }, refetchSplitsList] = useAxios('/api/splits')

    const [, createSplitRequest] = useAxios({
        url: '/api/splits',
        method: 'POST'
    }, { manual: true })

    const [, updateSplitRequest] = useAxios({
        method: 'PUT'
    }, { manual: true });

    const [, removeSplitRequest] = useAxios({
        method: 'DELETE'
    }, { manual: true });

    const createSplit = (splitData) => {
        return createSplitRequest({
            data: prepareSplitForRequest(splitData)
        }).then(() => {
            refetchSplitsList();
            setCreateSplitDialogVisibility(false);
            enqueueSnackbar(`Saved!`, {
                variant: 'success',
            });
        });
    }

    const updateSplit = (splitData, splitId) => {
        return updateSplitRequest({
            url: `/api/splits/${splitId || editableSplit.id}`,
            data: prepareSplitForRequest(splitData)
        }).then(() => {
            enqueueSnackbar(`Saved!`, {
                variant: 'success',
            });
            // updating current split active status
            const split = data.find(s => s.id === splitId);
            if(split) {
                split.active = splitData.active;
            }
        });
    }

    const updateSplitWithEditForm = (...args) => {
        return updateSplit(...args)
            .then(() => {
                refetchSplitsList();
                selectSplitForEdit(null);
            })
    }

    const onRemoveSplitHandler = () => {
        return removeSplitRequest({
            url: `/api/splits/${removableSplit.id}`
        }).then(() => {
            refetchSplitsList();
            enqueueSnackbar(`Removed!`, {
                variant: 'success',
            });
            selectSplitForRemove(null);
        });
    }

    return (
        <>
            <AddSplitButton onClick={() => setCreateSplitDialogVisibility(true)}/>
            <LoadPanel className={classes.cards} loading={loading} error={error || undefined}>
                <div className={classes.cards}>
                    {data && data.map(split =>
                        <Split isImmutable={split.name === 'Paypal'} data={split} key={split.id}
                            onChangeActivity={(active) => updateSplit({ ...split, active }, split.id)}
                            onEditBtnClick={() => selectSplitForEdit(split)}
                            onDeleteBtnClick={() => selectSplitForRemove(split)}
                        />
                    )}
                </div>
            </LoadPanel>
            {showCreateSplitDialog &&
                <NewSplitFormDialog
                    onClose={() => setCreateSplitDialogVisibility(false)}
                    onSave={createSplit} />
            }
            {editableSplit &&
                <EditSplitFormDialog
                    formData={prepareSplitDataForForm(editableSplit)}
                    onClose={() => selectSplitForEdit(null)}
                    onSave={(splitData) => updateSplitWithEditForm(splitData, editableSplit.id)} />
            }
            {removableSplit &&
                <SplitRemoveDialog
                    split={removableSplit}
                    onClose={() => selectSplitForRemove(null)}
                    onRemove={onRemoveSplitHandler} />
            }
        </>
    );
}