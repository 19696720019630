import React from 'react';

import { BrowserRouter as Router, Switch, Route, Redirect, useLocation} from "react-router-dom";
import { SnackbarProvider } from 'notistack';


import { AuthUserProvider, useAuthUserContext } from './js/context/auth-user-context';

import LoginView from './js/views/login';
import ForgotPasswordView from './js/views/forgot-password';
import PasswordRecoveryView from './js/views/password-recovery';
import EmailVerified from './js/views/email-verified';

import BaseView from './js/views/main/base';

import UsersList from './js/views/users/list';
import LoadView from './js/views/main/load';
import ProfileView from './js/views/users/profile';
import GroupsList from './js/views/groups/list';
import SplitsList from './js/views/splits/list';
import SalesView from './js/views/sales/list';
import SalesBySplitView from './js/views/sales/split-grouping-list';
import TrainingSalesView from './js/views/sales/trainings';


function AuthorizedRoute(props) {
    const { authUser, isSigningIn, isLoggingOut, isLogginedOut } = useAuthUserContext();

    if (isSigningIn || isLoggingOut) {
        return <Route {...props}/>;
    }
    if (!authUser) {
        let redirect = '';
        if(!isLogginedOut && props.location.pathname && props.location.pathname !== '/') {
            const query = new URLSearchParams();
            query.set('redirect', props.location.pathname + props.location.search);
            redirect = `?${query.toString()}`
        }
        return <Redirect to={{pathname: '/login', search: redirect}}/>
    }
    return <Route {...props}/>;
}

function UnauthorizedRoute(props) {
    const { authUser, isSigningIn, isLoggingOut } = useAuthUserContext(),
        location = useLocation();

    if (!(isSigningIn || isLoggingOut) && authUser) {
        const query = new URLSearchParams(location.search);
        return <Redirect to={{ pathname: query.get('redirect') || '/' }} />
    }

    return <Route {...props} />
}

function AuthorizedApp() {
    const { authUser, isSigningIn, isLoggingOut } = useAuthUserContext();

    if (isSigningIn || isLoggingOut) {
        return <LoadView/>
    }

    return <BaseView>
        <Switch>
            <Route exact path="/sales/toolboxes" component={SalesView}/>
            <Route exact path="/sales/trainings" component={TrainingSalesView}/>
            <Route exact path="/sales/splits" component={SalesBySplitView}/>
            <Route exact path="/profile" component={ProfileView}/>
            {authUser.superadmin && <Route exact path="/groups" component={GroupsList}/>}
            {authUser.superadmin && <Route exact path="/splits" component={SplitsList}/>}
            {authUser.superadmin && <Route exact path="/users" component={UsersList}/>}
            <Redirect exact from="/" to="/sales/toolboxes"/>
            <Route>Not found</Route>
        </Switch>
    </BaseView>
}

function App() {
    return (
        <SnackbarProvider anchorOrigin={{horizontal: 'right', vertical: 'top'}}>
            <AuthUserProvider>
                <Router>
                    <Switch>
                        <UnauthorizedRoute path="/login" component={LoginView}/>
                        <UnauthorizedRoute path="/forgot-password" component={ForgotPasswordView}/>
                        <UnauthorizedRoute path="/passwordrecovery" component={PasswordRecoveryView}/>
                        <Route path="/emailverified" component={EmailVerified}/>
                        <AuthorizedRoute path="/" component={AuthorizedApp}/>
                    </Switch>
                </Router>
            </AuthUserProvider>
        </SnackbarProvider>
    )
}

export default App;
