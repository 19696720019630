import React, {useState} from 'react';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useAuthUserContext } from '../../../context/auth-user-context';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ChangePasswordDialog from './change-password-dialog';
import VeryfiEmailDialog from './verify-email-dialog';

export default function SecurityTab() {
    const { t } = useTranslation();
    const { authUser } = useAuthUserContext();
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showVerifyEmailConfirm, setVerifyEmailConfirm] = useState(false);
    return <List>
        <ListItem>
            <ListItemIcon>
                <LockIcon />
            </ListItemIcon>
            <ListItemText primary={t('general.text.password')} />
            <ListItemSecondaryAction>
                <Button variant="contained" color="primary" onClick={() => setShowChangePassword(true)}>
                    {t('general.text.change')}
                </Button>
            </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
            <ListItemIcon>
                <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={t('page.profile.email_verification')} />
            <ListItemSecondaryAction>
                {authUser.emailVerified
                    ? <VerifiedUserIcon fontSize="large" style={{ color: green[500] }}/>
                    : <Button variant="contained" color="primary" onClick={() => setVerifyEmailConfirm(true)}>
                            {t('general.text.verify')}
                        </Button>
                }
            </ListItemSecondaryAction>
        </ListItem>
        {showChangePassword && <ChangePasswordDialog onClose={() => setShowChangePassword(false)}/>}
        {showVerifyEmailConfirm && <VeryfiEmailDialog onClose={() => setVerifyEmailConfirm(false)}/>}
    </List>
}