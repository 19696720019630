import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from '../../../components/text-field';
import { useTranslation } from 'react-i18next';
import { useAuthUserContext } from '../../../context/auth-user-context';
import L from '../../../../locale';
import LoadingButton from '../../../components/loading-button';

const Schema = Yup.object().shape({
     fullName: Yup.string()
         .min(2, L.t('general.text.validation.too_short'))
         .max(60, L.t('general.text.validation.too_long'))
         .required(L.t('general.text.validation.required')),
     email: Yup.string()
         .email(L.t('general.text.validation.invalid_email'))
         .required(L.t('general.text.validation.required'))
});

export default function PersonalInfoTab() {
    const { t } = useTranslation();
    const { authUser, updateUser } = useAuthUserContext();
    const initialValues = {
        fullName: authUser.fullName,
        email: authUser.email
    };

    const fieldProps = {
        variant: "outlined",
        margin: 'normal',
        fullWidth: true
    };

    const onSubmitHandler = ({email, fullName}) => updateUser(email, fullName);

    return <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={onSubmitHandler}
        >
        {({ submitForm, isSubmitting, isValid, values: {email, fullName} }) =>
            <Form>
                <Field component={TextField} autoFocus type="text" {...fieldProps}
                        name="fullName"
                        label={t('page.users.user_name')} />
                <Field component={TextField} type="email" {...fieldProps}
                        name="email"
                        label={t('page.users.email_address')}/>
                <LoadingButton variant="contained" color="primary"
                    disabled={(email === initialValues.email && fullName === initialValues.fullName)
                            || !isValid || isSubmitting} onClick={submitForm} loading={isSubmitting}>
                    {t('general.text.save')}
                </LoadingButton>
            </Form>
        }
    </Formik>
}
