import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ExportButton from './export-button';
import { LoadPanel } from '../../components/load-panel';
import useAxios from '../../hooks/use-axios';
import { useAuthUserContext } from '../../context/auth-user-context';
import {headLabelRender, calculateTransactionsGrandTotal, grandTotalHeadLabelOptions,
    GROUPED_TABLE_COLUMNS, NOT_VISIBLE, TRANSACTION_COUNT, TOTAL_SEATS, GROSS_PRICES,
    NET_SALE_PRICES, TAX, NET_TOTAL_SALE, SPLIT_TOTAL, REGISTRATION_ID, SPLIT_NAME,
    ACTIVE_SEATS, LICENSE_ID, TRANSACTION_DATE, DISCOUNT, SPLIT_ITEM_NAME} from './sales-columns';
import {SplitSummaryExporter, SplitTransactionExporter,
    SplitItemSummaryExporter, SplitItemTransactionExporter} from './export-formats';
import Datatable, {useDatatableDateRangeTitle} from './datatable';
import ExpandableRow from './detail-row';
import DetailsTable from "./details";

const CSV_FILE_NAME = "SalesBySplitGroup.csv";

function renderDetailsTable(split, items, rowData, printTitle, authUser) {
    const id = rowData[0],
        item = items.find(t => t.id === id),
        transactions = item.transactions,
        isToolbox = split.toolboxes.indexOf(item) !== -1,
        detailsColumns= [
            NOT_VISIBLE('id'),
            NOT_VISIBLE('splits'),
            ...(
                isToolbox
                    ? [LICENSE_ID(authUser), TOTAL_SEATS(), ACTIVE_SEATS()]
                    : [REGISTRATION_ID(authUser), TOTAL_SEATS()]
            ),
            TRANSACTION_DATE(),
            GROSS_PRICES(),
            DISCOUNT(),
            NET_SALE_PRICES(),
            TAX(),
            SPLIT_TOTAL()
        ],
        detailexExportButton = new ExportButton(CSV_FILE_NAME, [
            new SplitItemTransactionExporter([item], split, authUser)
        ]);
    return <ExpandableRow key={id} colSpan={rowData.length + 1}>
        <DetailsTable data={transactions} columns={detailsColumns}
            title={printTitle} exportButton={detailexExportButton}
        />
    </ExpandableRow>
}

function renderGroupedTable(splits, rowData, printTitle, authUser) {
    const id = rowData[0],
        split = splits.find(s => s.id === id);
    const items = [...split.toolboxes, ...split.trainings];
    const columns = GROUPED_TABLE_COLUMNS(items, SPLIT_ITEM_NAME, false);
    const exportButton = new ExportButton(CSV_FILE_NAME, [
        new SplitItemSummaryExporter(items, split),
        new SplitItemTransactionExporter(items, split, authUser)
    ]);

    return <ExpandableRow key={id} colSpan={rowData.length + 1}>
        <Datatable
            title={printTitle}
            data={items}
            columns={columns}
            customToolbar={() => exportButton.render()}
            renderExpandableRow={(rowData) =>
                renderDetailsTable(split, items, rowData, printTitle, authUser)}
            elevation={0}
        />
    </ExpandableRow>
}

export default function SplitsGroupingList() {
    const { t } = useTranslation();
    const { authUser } = useAuthUserContext();

    const [start, end, title, printTitle] = useDatatableDateRangeTitle(t('page.sales.by_split.title'),
        t('page.sales.by_split.title'));

    const [ { data, loading, error }] = useAxios({
        url: '/api/sales/groupby/split',
        params: { start: start.toISOString(), end: end.toISOString()}
    });

    const splits = data || [];

    const columns = useMemo(()=> {
        const splitsItems = splits.reduce((res, split) =>
            res.concat(split.toolboxes, split.trainings), []);
        const totals = calculateTransactionsGrandTotal(splitsItems, authUser);
        return [
            NOT_VISIBLE('id'),
            SPLIT_NAME({
                customHeadLabelRender: (column) => headLabelRender(column, null)
            }),
            NET_TOTAL_SALE({
                ...grandTotalHeadLabelOptions(totals),
                display: authUser.superadmin,
                filter: authUser.superadmin,
                download: authUser.superadmin
            }),
            TRANSACTION_COUNT(grandTotalHeadLabelOptions(totals)),
            TOTAL_SEATS(grandTotalHeadLabelOptions(totals)),
            GROSS_PRICES(grandTotalHeadLabelOptions(totals)),
            NET_SALE_PRICES(grandTotalHeadLabelOptions(totals)),
            TAX(grandTotalHeadLabelOptions(totals)),
            SPLIT_TOTAL(grandTotalHeadLabelOptions(totals))
    ]}, [authUser, splits]);

    const exportButton = new ExportButton(CSV_FILE_NAME, [
        new SplitSummaryExporter(splits),
        new SplitTransactionExporter(splits, authUser)
    ]);

    return (
        <LoadPanel loading={loading} showContent error={error || undefined}>
            <Datatable
                title={title}
                data={splits}
                columns={columns}
                customToolbar={() => exportButton.render()}
                renderExpandableRow={(rowData) =>
                    renderGroupedTable(splits, rowData, printTitle, authUser)}
            />
        </LoadPanel>
    );
}