import React from 'react';
import AppBar from './app-bar';
import Content from './content';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    }
}));

export default function LoadView(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBar/>
            <Content/>
        </div>
    );
}