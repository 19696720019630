import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TranslateIcon from '@material-ui/icons/Translate';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function LanguageButton() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button color="inherit" onClick={handleClick}>
                <TranslateIcon/> English <KeyboardArrowDownIcon size="small"/>
            </Button>
            <Menu anchorEl={anchorEl} open={!!anchorEl}
                onClose={handleClose}>
                <MenuItem>English</MenuItem>
            </Menu>
        </>
    );
}
