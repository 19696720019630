import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
    detailRow: {
        paddingTop: 0,
        paddingBottom: 24,
        '@media print': {
            //fixed MuiTableCell-sizeSmall:last-child style
            '&:last-child': {
                paddingRight: 0
            },
            paddingLeft: 0
        }
    }
}));

export default function ExpandableRow({ colSpan, children }) {
    const classes = useStyles();
    return <TableRow style={{ backgroundColor: grey[200] }} >
        <TableCell size={"small"} colSpan={colSpan} className={classes.detailRow}>
            {children}
        </TableCell>
    </TableRow>
}